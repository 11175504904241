import SortableTree from "react-sortable-tree";
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { maxLengthCheck } from "../../../../lib/helpers";
import toast from 'react-hot-toast';
import InputField from "../../../layouts/elements/fields/input";
import { FormattedMessage } from "react-intl";
import InputSearchField from "../../../layouts/elements/fields/inputSearch";
import { SelectSortableTree } from "../../../themes/userq/Tree/select_sortable_tree";
import { Search } from "../../../themes/userq/Search/Search";
import { Text } from "../../../themes/userq/Text/Text";
import { Input } from "../../../themes/userq/Input/Input";
import FollowupQuestions from "./followup_questions";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import ConfirmationModal from "../../../themes/userq/Modal/ConfirmationModal";

const  Task = React.forwardRef(({setIsSaved,test,updateTask, changeOrder,setConfirmDeleteTaskModal, ...props},ref)=>{
	const [isTreeVisible, setTreeVisible] = useState(false);
	const [search, setSearch] = useState("");
	const [tree, setTree] = useState(props.tree);
	const [formErrors, setFormErrors] = useState(props.errors);
	const [formValues, setFormValues] = useState({ "correct_answer_id": props.task.correct_answer_id, "correct_answer": props.task.correct_answer, "title": props.task.title });

	const [answerPath, setAnswerPath] = useState([]);

	const [questions, setQuestions] = useState(props.task.questions);

	const followupRef = useRef(null);

    const [tempAddQuestion, setTempAddQuestion] = useState({
        question: "",
        is_optional: false,
        is_logic: false,
        jump_to: "End Survey",
        options:[]
    });

    useImperativeHandle(ref, () => { return{
        followupRef: followupRef.current
    }},[tempAddQuestion]);

	useEffect(() => {

		setTimeout(function () {
			window.setLabels();
		}, 1000);
	}, []);
	useEffect(() => {

		
		setTree(props.tree);
		setAnswerPath([]);
		navigateTree(props.tree, "answer", [], props.task.correct_answer_id);

	}, [props.tree]);

	useEffect(() => {

		setTree(props.tree);
		setAnswerPath([]);
		navigateTree(props.tree, "answer", [], props.task.correct_answer_id);

	}, [props.nodeUpdated]);

	useEffect(() => {
		setFormValues({...formValues, "correct_answer_id": props.task.correct_answer_id});
	}, [props.task.correct_answer_id]);


	useEffect(() => {
		console.log(props.task);
		setQuestions(props.task.questions);
		setFormValues({...formValues, "correct_answer_id": props.task.correct_answer_id, "correct_answer": props.task.correct_answer, "title": props.task.title });
		navigateTree(props.tree, "answer", [], props.task.correct_answer_id)
	}, [props.task]);



	useEffect(() => {

		setFormErrors(props.errors);

	}, [props.errors]);
	const updateCorrectAnswer = (id, title) => {
		//return (e) => {

		//e.preventDefault();

		if (!title) {

			setFormErrors({ ...formErrors, answer_error: 'Required', answer: id });
			setTree(tree)

		} else {

			setFormErrors({...formErrors, answer_error:null})

			setFormValue("correct_answer_id", id);

			setFormValue("correct_answer", title);
			setAnswerPath([]);


			setTreeVisible(false);
			var task = props.task;
			task.correct_answer_id = id;
			task.correct_answer = title;

			navigateTree(tree, "answer", [], id);


			props.onBlur(task, props.index);

			//navigateTree(tree,"answer",[]);

			/*var task = props.task;
			task.correct_answer_id = formValues.correct_answer_id;
			task.correct_answer = formValues.correct_answer;
			task.title = formValues.title;
			console.log(task)
			props.onChange(task, props.index);*/
		}


		//};
	};
	const saveCorrectAnswer = () => {
		resetFormErrors();
		if (!formValues.title) {
			setFormErrors({ ...formErrors, "title": "Required", "error_class": "input_error" });
		} else if (!formValues.correct_answer_id) {

			setFormErrors({ ...formErrors, answer_error: 'Required' });

			toast((
				<div className='toastinner'>
					<FormattedMessage id="Please select a correct answer" />
					<a onClick={() => { toast.dismiss(); }}>&times;</a>
				</div>), {
				className: 'errortoast',
				position: 'bottom-center'
			});

		} else {
			resetFormErrors();
			setTreeVisible(false);
			var task = props.task;
			task.correct_answer_id = formValues.correct_answer_id;
			task.correct_answer = formValues.correct_answer;
			task.title = formValues.title;
			//console.log(task)
			navigateTree(tree, "answer", []);
			props.onChange(task, props.index);
		}
	};
	const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
	const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
	};
	const resetFormErrors = () => {
		let formErrorsLocal = Object.assign({}, formErrors);

		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		setFormErrors(formErrorsLocal);
	};
	const clearFormValue = (name) => {
		return ({ target: { value } }) => {
			console.log(value)
			setTimeout(function () {
				setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
			}, 1000);
		};
	};
	const getNodeBg = (extendedNode) => {
		if (extendedNode.path.length == 2) {
			return "rgba(150, 255, 252, 0.8)";
		}
		else if (extendedNode.path.length == 3) {
			return "rgba(150, 255, 252, 0.35)";
		}
		else if (extendedNode.path.length >= 4) {
			return "rgba(150, 255, 252, 0.2)";
		} else {
			return "rgba(150, 255, 252, 1)";
		}
	}
	const navigateTree = (tree, task, path, correct_answer_id = null) => {
		tree.forEach(function (item) {
			var pathL = [];
			pathL = Object.assign([], path);
			if (task == "answer") {
				//item.id == formValues.correct_answer_id || 
				if (item.id == correct_answer_id) {
					pathL.push({ id: item.id, title: item.title })
					setAnswerPath(pathL);
					props.updateTaskPath(props.index, pathL);
					return;
				}
			}
			if (item.children.length > 0) {
				pathL.push({ id: item.id, title: item.title })
				navigateTree(item.children, task, pathL, correct_answer_id);
			}
		})
	}
	const renderAnswerPath = () => {

		//console.log(answerPath)
		var path = [];

		answerPath.forEach(function (item, index) {

			if (index == answerPath.length - 1) {
				path.push((
					<span key={"item" + index} className="answer-path pink-color">{item.title}</span>
				))
			} else {
				path.push((
					<span key={"item" + index} className="answer-path">{item.title} &gt; </span>
				))
			}

		})
		return path;
	}
	return (
		<>
		<div className="card-sorting-test-wrap tree-test-task-wrap">
												
			<div className="cardsorting-wrap-mid-data">
				<div className="build-tree-task-wrap">
					<div className="build-tree-task-title-wrap">
						<div className="build-treetask-top-left">
							<Text type={'body-text-2'}>{props.title}</Text>
						</div>
						{props.total_tasks > 1 &&
							<div className="build-treetask-top-right">

								<span className="delete-tree-task" 
									onClick={() => { 
										if(setConfirmDeleteTaskModal){
											setConfirmDeleteTaskModal({ open: true, task_index: props.index });
										} 
									}}>
									<Icon 
										color={"gray-700-svg"}
										value={"delete"}
										size={"medium"}
										hover={true}
									/>
								</span>
		
							</div>
						}
					</div>

					<div className="tree-sort-task full-width-input" id={"task"+props.task.id}>
						<Input id={"Add Task"} rtl={(props.language == "ar") ? true : false} label={"Task description"} maxLength="300" error={formErrors.title} error_class={formErrors.error_class} onClear={() => {
							clearFormValue('title')

							var task = props.task;
							task.title = '';
							setFormValues({...formValues,'title':''})
							props.onBlur(task, props.index);

							

						}} value={formValues.title == null ? '' : formValues.title} onChange={(e) => {
							var task = props.task;
							task.title = e.target.value;
							setFormValues({ ...formValues, 'title': e.target.value })
							props.onChange(task, props.index);
							setFormErrors({...formErrors, title:null});
							props.setStepperError(false);

						}} onInput={maxLengthCheck}
						onBlur={(e) => {
							var task = props.task;
							task.title = e.target.value;
							setFormValues({ ...formValues, 'title': e.target.value })
							props.onBlur(task, props.index);
							
							if(!e.target.value){
								
								setFormErrors({...formErrors,'title':'A task description is required'});
							} else {
								props.setStepperError(false);
							}

						}}
						required={true}
						/>

					</div>
					{!isTreeVisible && (
						<div className="build-task-answer-wrap" id={"task-answer"+props.task.id}>
							{!props.task.correct_answer_id && (
								<div className="define-task-answer-wrap"
									
								>
								
									<div className="color-black">
										<Text type={'body-text-2'}>Define correct answer</Text>
									</div>
									{/*${formErrors.answer_error ? 'error_class' : ''} */}
									<button onClick={() => {
										if (tree.length > 0) {
											setTreeVisible(true);
										}
									}}  className={`define-task-answer secondary-btn secondary-small ${tree.length == 0 ? "btn-disabled" : ""} `}>Choose</button>
									{formErrors.answer_error &&
									<div className="error red-text">
										{formErrors.answer_error}
									</div>
									}
								</div> 
							)}
							{props.task.correct_answer_id && (
								<>
									<div className={`correct-answer body-text-2 medium-font`}>
										<span>The correct answer is:</span>
										<div className="correct-asnwer-div">
											<span className={`${props.language == "ar"
												? "arabic-font"
												: ""
												}`}>
												{renderAnswerPath()}
											</span>
											<span
												className="edit-icon" onClick={() => {
													setTreeVisible(true);
												}}>
												<Icon 
													value={"edit"} 
													
													size={"small"}
													hover={true}
												/>
											</span>
										</div>
									</div>

								</>
							)}
						</div>
					)}
					{isTreeVisible && (
						<>
							<div className="build-task-answer-wrap" id={"task-answer"+props.task.id}>
								
								<div className={`build-tree-task-search-hold mt-20`}>
									<div className="color-black">
										<Text type={'body-text-2'}>Define correct answer</Text>
									</div>

									{isTreeVisible && (
										<div className="build-tree-search-wrap mt-20 tree-task-search-wrap">
											<Search
												label="Search"
												onChange={(event) => {
													setSearch(event.target.value);
												}}
												onClear={(e) => {
													setSearch("");
												}}
												value={search}
												placeholder="Search"
												rtl={`${props.language == "ar" ? 'arabic_wrapper' : ''}`}
											/>

											<div className="close-node-btn-wrap">
												<button type="button" onClick={() => {
													setTreeVisible(false);
												}} className="btn primary-btn primary-small btn-with-icon btn-with-icon-left">
													<img className="icon-small"
														src={process.env.REACT_APP_URL + "img/cross-white.svg"}
													/> Close
												</button> 
											</div>

										</div>
									)}
								</div>
								<SelectSortableTree
									language={props.language}
									formValues={formValues}
									setFormValues={setFormValues}
									tree={tree}
									setTree={setTree}
									formErrors={formErrors}
									setFormErrors={setFormErrors}
									updateCorrectAnswer={updateCorrectAnswer}
									search={search}
								/>
								{formErrors.answer_error &&
								<div className="error red-text">
									{formErrors.answer_error}
								</div>
								}
							</div>
							


						</>
					)}
					
				</div>
				{((formValues.title && formValues.correct_answer_id) || (props.task && props.task.questions.length > 0)) &&
				<div className="tree-test-task-follow-wrapper">
					<div className={`tree-test-task-followup-questions prototype-task-followup-questions ${formErrors.questions?"prototype-questions-error":""} `} id={"questions_"+props.task.id}>
						<FollowupQuestions 
							tempAddQuestion={tempAddQuestion} 
							onUpdate={(question)=>{
								setTempAddQuestion(question);
							}} 
							ref={followupRef} 
							objective={"closed"} 
							maxQuestions={1} 
							followupQuestions={questions?questions:[]} 
							isLoading={false} 
							updateQuestions={(questions)=>{

								var taskLoc = {...props.task};

								taskLoc.questions = questions;

								if(questions.length>0){
									
									if(formErrors.questions){
										props.setStepperError(false);
									}
									
									setFormErrors({...formErrors, questions:null});

									
								}

								updateTask(taskLoc, props.index);
							}} 
							test={test} 
							task={props.task} 
							setIsSaved={setIsSaved} 
						/>

						<span className="error red-text">
							{formErrors.questions}
						</span>
					</div>
				</div>
				}
			</div>
		</div>
		</>
	);
});
export default Task;
