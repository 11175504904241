import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { validateResultData } from "../../../services/test_result";
import toast from "react-hot-toast";
import CheckboxNormal from "../../layouts/elements/fields/checkbox_normal";
import { LoadingIcon } from "../../themes/userq/Loader/loadingIcon";
import { Button } from "../../themes/userq/Button/Button";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import { showToast } from "../../../lib/helpers";
import { Input } from "../../themes/userq/Input/Input";


export default function NdaModal({ openModal, closeModal, result, timeSpent, testLanguage, methodology, testDevice, testerLimitExceed, testerNotMatchedInTargetGroup, isRegisteredUser, isPaidUser, score }) {

    const [formErrors, setFormErrors] = useState({ privacy: null, privacy_error_class: null });

    const [formValues, setFormValues] = useState({ name: "" });

    const [ndaLoading, setNdaLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        // reset form values when popup again open
        if (!openModal) {
            setFormValues({ name: "" });
        }

    }, [openModal]);

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        !(ReactSession.get("token")) ? navigate('/tester/sign-in') : navigate('/')
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);



    const validateNda = (e) => {

        e.preventDefault();
        if (!formValues.name) {
            showToast('Please enter your name', "error");
        } else {
            const token = ReactSession.get("token");
            const data = new FormData();
            data.append("test_id", result.test_id);
            data.append("guest_token", ReactSession.get("guest_token"));
            data.append("validate", 'nda');
            data.append("result_id", result.id);
            data.append("type", 't');
            data.append("time_spent", timeSpent);
            data.append("name", formValues.name);

            setNdaLoading(true);
            validateResultData(data, token).then((response) => {
                setNdaLoading(false);

                if (response.success) {

                    // console.log('coming')
                    // close all popup if session timeout poup come
                    ReactSession.set("isCheckNdaPopupNotOpened", 'false');

                    closeModal(true);
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    }

    return (
        <Modal
            show={methodology === 'Card Sorting' ? ((document.body.clientWidth > 767) ? openModal : false) :
                ((document.body.clientWidth > 767 && testDevice === 'mobile')) ? false :
                    (document.body.clientWidth < 768 && testDevice === 'desktop') ? false : openModal}
            centered
            className="fade custom-modal-wrap  nda-agreement-modal "
        >
            <Modal.Body className={(testLanguage == "ar") ? "arabic_wrapper" : ""} ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={"h3"}>
                            {(testLanguage == "en") ? 'Review & accept the test guidelines' : 'مراجعة وقبول إرشادات الاختبار'}
                        </Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                //closeModal();
                                { !(ReactSession.get("token")) ? navigate('/tester/sign-in') : navigate('/') }
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon colorClass={"gray-900-svg"} value={'close'} size={'large'} hover={true} />
                            </span>
                        </button>
                    </div>

                    <div className="nda-modal-text mb-32">
                        {isRegisteredUser &&
                        <>
                                <span class={`score-info ${score > 80 ? "green-star" : ""} ${60 < score && score <= 80 ? "purple-star" : ""} ${50 < score && score <= 60 ? "yellow-star" : ""} ${score <= 50 && score != null ? "red-star" : ""} ${score == null ? "white-star" : ""}`}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.3067 4.66529C15.3631 4.52774 15.4591 4.41006 15.5826 4.32724C15.706 4.24442 15.8513 4.2002 16 4.2002C16.1487 4.2002 16.294 4.24442 16.4174 4.32724C16.5409 4.41006 16.6369 4.52774 16.6933 4.66529L19.5267 11.48C19.5797 11.6075 19.6669 11.7179 19.7786 11.799C19.8904 11.8802 20.0223 11.929 20.16 11.94L27.5173 12.5293C28.1827 12.5826 28.452 13.4133 27.9453 13.8466L22.34 18.6493C22.2353 18.7389 22.1572 18.8556 22.1144 18.9866C22.0716 19.1176 22.0657 19.2578 22.0973 19.392L23.8107 26.572C23.8451 26.716 23.8361 26.867 23.7847 27.006C23.7334 27.1449 23.642 27.2655 23.5222 27.3525C23.4023 27.4395 23.2594 27.4891 23.1114 27.4949C22.9634 27.5008 22.817 27.4626 22.6907 27.3853L16.3907 23.5386C16.273 23.4669 16.1378 23.429 16 23.429C15.8622 23.429 15.727 23.4669 15.6093 23.5386L9.30933 27.3866C9.18301 27.464 9.03661 27.5021 8.88861 27.4963C8.74061 27.4904 8.59767 27.4409 8.47782 27.3538C8.35798 27.2668 8.26662 27.1462 8.21527 27.0073C8.16392 26.8684 8.1549 26.7173 8.18933 26.5733L9.90267 19.392C9.93444 19.2578 9.9286 19.1175 9.88579 18.9865C9.84297 18.8555 9.76485 18.7388 9.66 18.6493L4.05467 13.8466C3.94242 13.7501 3.86121 13.6226 3.82127 13.48C3.78132 13.3375 3.78441 13.1863 3.83015 13.0455C3.8759 12.9047 3.96225 12.7806 4.07835 12.6887C4.19446 12.5969 4.33512 12.5414 4.48267 12.5293L11.84 11.94C11.9777 11.929 12.1096 11.8802 12.2214 11.799C12.3331 11.7179 12.4203 11.6075 12.4733 11.48L15.3067 4.66529Z" stroke="#BFF0A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                        Your score is {score}%
                                    </Text>
                                </span>
                            <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                {(testLanguage == "en") ?
                                    "Please review the following statements before starting the test."
                                    :
                                    "يرجى قراءة العبارات التالية والموافقة عليها قبل البدء في الاختبار."
                                }
                            </Text>

                            <div className={'nda-modal-list-items'}>
                                <ul>
                                    <li>
                                        <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                            {(testLanguage == "en") ?
                                                <>
                                                    Low quality submissions may not be eligible for payment and your Tester Score may be affected.

                                                </>
                                                :
                                                <>
                                                    قد لا تكون المشاركات ذات الجودة المنخفضة مؤهلة للدفع وقد يتأثر تقييمك الإجمالي.
                                                </>
                                            }
                                        </Text>
                                    </li>
                                    <li>
                                        <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                            {(testLanguage == "en") ?
                                                <>
                                                    Any violation, fraudulent activity, or use of profanity may lead to account suspension.
                                                </>
                                                :
                                                <>
                                                    أي انتهاك أو نشاط احتيالي أو استخدام لألفاظ بذيئة قد يؤدي إلى تعليق الحساب.
                                                </>
                                            }
                                        </Text>
                                    </li>
                                    <li>
                                        <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                            {(testLanguage == "en") ?
                                                <>
                                                    By participating, you agree to adhere to UserQ's <a
                                                    href={process.env.REACT_APP_SITE_URL + "privacy-policy"}
                                                    target="_blank">privacy policy</a> and <a
                                                    href={process.env.REACT_APP_SITE_URL + "terms-of-use"}
                                                    target="_blank">terms of use</a> and agree to not discuss, copy or share the test contents.
                                                </>
                                                :
                                                <>
                                                    من خلال مشاركتك في هذا الاختبار، فإنك توافق على الالتزام <a href={process.env.REACT_APP_SITE_URL + "privacy-policy"} target="_blank">بسياسة الخصوصية</a> UserQ <br />و<a href={process.env.REACT_APP_SITE_URL + "terms-of-use"}>شروط لمستخدم</a> وتوافق على عدم مناقشة أو نسخ أو مشاركة محتوى الاختبار.
                                                </>
                                            }
                                        </Text>
                                    </li>
                                </ul>
                            </div>
                            <Text type={"body-text-3"} fontWeight={'medium-font'} cssClasses={"mt-20"}>
                                {(testLanguage == "en") ?
                                    <>
                                        Kindly confirm your agreement by signing your full name:
                                    </>
                                    :
                                    <>
                                        الرجاء تسجيل الدخول أدناه عن طريق كتابة اسمك الكامل
                                    </>
                                }
                            </Text>
                        </>
                        }
                        {!isRegisteredUser &&
                        <>
                            <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                {(testLanguage == "en") ?
                                    <>
                                        By participating, you agree to adhere to UserQ's <a
                                        href={process.env.REACT_APP_SITE_URL + "privacy-policy"}
                                        target="_blank">privacy policy</a> and <a
                                        href={process.env.REACT_APP_SITE_URL + "terms-of-use"}
                                        target="_blank">terms of use</a>. Please refrain from discussing, copying, or sharing the test contents.
                                    </>
                                    :
                                    <>
                                        من خلال مشاركتك في هذا الاختبار، فإنك توافق على الالتزام <a href={process.env.REACT_APP_SITE_URL + "privacy-policy"}>بسياسة الخصوصية</a> UserQ <a href={process.env.REACT_APP_SITE_URL + "terms-of-use"}>وشروط الاستخدام</a>.
                                        <br />يرجى الامتناع عن مناقشة أو نسخ أو مشاركة محتويات الاختبار.

                                    </>
                                }
                            </Text>

                            <Text type={"body-text-3"} fontWeight={'medium-font'} cssClasses={"mt-20"}>
                                {(testLanguage == "en") ?
                                    <>
                                        Kindly confirm your agreement by signing your full name:
                                    </>
                                    :
                                    <>
                                        الرجاء تسجيل الدخول أدناه عن طريق كتابة اسمك الكامل
                                    </>
                                }
                            </Text>
                        </>
                        }



                        <Input
                            label={"Your full name"}
                            //label={testLanguage == "en" ? "Your full name" : "تحديد أخرى"}
                            maxLength="100"
                            language={testLanguage}
                            value={formValues.name}
                            rtl={testLanguage == "en" ? false : true}
                            readOnly={false}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    name: e.target.value,
                                });
                            }}
                            onClear={() => {
                                setFormValues({
                                    ...formValues,
                                    name: "",
                                });
                            }}
                            //error={(!formErrors.name)?'Required field':''}
                            required={true}
                        />

                        {/*<Text type={"body-text-3"} fontWeight={'medium-font'}>*/}
                        {/*    {(testLanguage == "en") ?*/}
                        {/*        'Each test on UserQ is protected by our privacy policy - any data collected is confidential and contents should not be shared. Please ensure you complete the test as accurately as possible in order to successfully complete the study.'*/}
                        {/*        :*/}
                        {/*        'كل اختبار على UserQ محمي بموجب سياسة الخصوصية الخاصة بنا - أي بيانات يتم جمعها سرية وال ينبغي مشاركة أي من المحتويات. يرجى\n' +*/}
                        {/*        'التأكد من إكمال االختبار بأكبر قدر ممكن من الدقة من أجل إكمال الدراسة بنجاح.'*/}
                        {/*    }*/}
                        {/*</Text>*/}
                    </div>

                    {testerNotMatchedInTargetGroup && !testerLimitExceed &&
                    <div className={'nda-target-info'}>
                        <Icon value={'dollar-black'} />
                        <Text type={'caption'}>
                            {(testLanguage == "en") ?
                                <>
                                    You do not meet the demographic requirements for this test. Unfortunately, we will not be able to offer you any compensation for taking it.
                                </>
                                :
                                <>
                                    يبدو أنك لا تستوفي متطلبات المجموعة المستهدفة التي حددها الباحث لهذا الاختبار.
                                    لسوء الحظ ، لن نتمكن من تقديم أي تعويض لك مقابل الحصول عليه.
                                </>
                            }
                        </Text>
                    </div>
                    }

                    {(testerNotMatchedInTargetGroup && testerLimitExceed) &&
                    <div className={'nda-target-info'}>
                        <Icon value={'dollar-black'} />
                        <Text type={'caption'}>
                            {(testLanguage == "en") ?
                                <>
                                    You do not meet the demographic requirements for this test. Unfortunately, we will not be able to offer you any compensation for taking it.
                                </>
                                :
                                <>
                                    يبدو أنك لا تستوفي متطلبات المجموعة المستهدفة التي حددها الباحث لهذا الاختبار.
                                    لسوء الحظ ، لن نتمكن من تقديم أي تعويض لك مقابل الحصول عليه.
                                </>
                            }
                        </Text>
                    </div>
                    }

                    {!testerNotMatchedInTargetGroup && testerLimitExceed &&
                    <div className={'nda-target-info'}>
                        <Icon value={'dollar-black'} />
                        <Text type={'caption'}>
                            {(testLanguage == "en") ?
                                <>
                                    We have reached the participant limit for this test and will not be able to
                                    offer you any compensation for taking it.
                                </>
                                :
                                <>
                                    لقد وصلنا إلى الحد الأقصى للمشاركين في هذا الاختبار ولن نتمكن من تقديم أي تعويض
                                    لك مقابل خوضه.
                                </>
                            }
                        </Text>
                    </div>
                    }

                    {/*<div className={"terms-condition-text mb-20"}>&nbsp;*/}
                    {/*    <CheckBox*/}
                    {/*        id="terms_accept_toggle"*/}
                    {/*        checked={formValues.privacy ? true : false }*/}
                    {/*        label={<Text display="inline" fontWeight={'medium-font'} type="body-text-3">*/}
                    {/*            {(testLanguage == "en") ?*/}
                    {/*                <>I agree to the UserQ <a*/}
                    {/*                    href={process.env.REACT_APP_SITE_URL + "privacy-policy"} target="_blank">privacy*/}
                    {/*                    policy</a> and <a href={process.env.REACT_APP_SITE_URL + "terms-of-use"}*/}
                    {/*                                      target="_blank">T&C</a> and won’t discuss the contents of*/}
                    {/*                    the test I am about to take.</>*/}
                    {/*                :*/}
                    {/*                <>أوافق على <a href={process.env.REACT_APP_SITE_URL + "privacy-policy"} target="_blank"> سياسة الخصوصية</a> و <a href={process.env.REACT_APP_SITE_URL + "terms-of-use"} target="_blank">سياسة أحكام وشروط</a>*/}
                    {/*                    &nbsp;UserQ&nbsp; ولن أناقش محتویات الاختبار الذي أنا على وشك إجرائه*/}







                    {/*                </>*/}
                    {/*            }*/}

                    {/*        </Text>}*/}
                    {/*        onChange={(e)=>setFormValues({...formValues,"privacy":!formValues.privacy})}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="confirm-buttons-wrap">

                        <Button
                            type="primary"
                            size="large"
                            label={(testLanguage == "en") ? "Agree" : 'أوافق'}
                            state={!(formValues.name) ? 'disabled' : 'active'}
                            microLoading={ndaLoading}
                            onClick={(e) => validateNda(e)} />

                    </div>

                </div>

            </Modal.Body>

        </Modal>
    );
}