import { useRef, useState } from "react";
import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import BarChart from "./bar_chart_v2";
import LinearAxisChart from "./linear_axis_chart_v2";
import exportAsImage from "../../../../lib/exportAsImage";

export const  TreeTasksOverviewStatsBox = ({tasks, title, hide}) =>{

    const ref = useRef();
    const tipref = useRef();
    const exportPngRef = useRef();

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false,
        show:false
    });

    const [showMore, setShowMore] = useState(false);


    const colors = [
        "rgba(166, 0, 0, 1)",
        "rgba(255, 211, 211, 1)",
        "rgba(204, 204, 204, 1)",
        "rgba(191, 240, 168, 1)",
        "rgba(55, 139, 16, 1)"
    ];
    const handlePointerMove = (data) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 150;


                var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 82;




                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
                let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if(event.clientX + tool_width/2 > window_width){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

                }
                if(event.clientX - tool_width/2 < 0){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
                }
                console.log(containerX)
                /*if(event.clientY-10+tool_height > window_height){
                    //alert(window_width)
                    containerY = ('clientX' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height -10;

                    <><div></div><div>{key["count"]+" out of "+key["total"]}</div><div>{key["subLabel"] +"%"}</div></>
                }*/
                showTooltip({
                    tooltipLeft: containerX,
                    tooltipTop: containerY,
                    tooltipData: <>{data}</>,
                    open:true,
                    show: tooltip.open && tipref && tipref.current ? true: false,
                    tipPostion: tipPosition
                });
            });
    }

    const renderTasks = () => {

        var length = showMore ? tasks.length : (tasks.length > 4 ? 4 : tasks.length);

        var renderTasks = [];
        for(var task=0; task<length; task++){

            renderTasks.push((
                <div key={"task"+task} className="task-overview-bar-with-title">
                    {!hide &&
                    <span>{"Task "+(task+1)}</span>
                    }
                    <div className="task-overview-bar">
                        <div onPointerMove={handlePointerMove(<>Direct failure <br/>{tasks[task]["direct_failure_count"]+ " participant"+ (tasks[task]["direct_failure_count"] > 1 ? 's':'') }</> ) }
                             onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } } style={{width:tasks[task]["direct_failure"]+"%", backgroundColor:colors[0]}}></div>
                        <div onPointerMove={handlePointerMove(<>Indirect failure <br/>{tasks[task]["indirect_failure_count"]+ " participant"+ (tasks[task]["indirect_failure_count"] > 1 ? 's':'') }</> ) }
                             onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } } style={{width:tasks[task]["indirect_failure"]+"%", backgroundColor:colors[1]}}></div>
                        <div  onPointerMove={handlePointerMove(<>Task skipped <br/>{tasks[task]["task_skipped_count"]+ " participant"+ (tasks[task]["task_skipped_count"] > 1 ? 's':'') } </> ) }
                              onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } } style={{width:tasks[task]["task_skipped"]+"%", backgroundColor:colors[2]}}></div>
                        <div onPointerMove={handlePointerMove(<>Indirect success <br/>{tasks[task]["indirect_success_count"]+ " participant"+ (tasks[task]["indirect_success_count"] > 1 ? 's':'') } </> ) }
                             onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } } style={{width:tasks[task]["indirect_success"]+"%", backgroundColor:colors[3]}}></div>
                        <div onPointerMove={handlePointerMove(<>Direct success <br/>{tasks[task]["direct_success_count"]+ " participant"+ (tasks[task]["direct_success_count"] > 1 ? 's':'') } </> ) }
                             onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } } style={{width:tasks[task]["direct_success"]+"%", backgroundColor:colors[4]}}></div>
                    </div>
                </div>));

        }
        return renderTasks;
    }
    const renderLegend = ()=>{
        let legends = [];

        let data = ["Direct failure","Indirect failure","Task skipped","Indirect success","Direct success"]

        var index = 0;
        for(var key in data){



            legends.push( (
                <div key={"legend"+key+index} className="chart-info-repeat">
                    <span style={{ "display": "inline-block", "backgroundColor": colors[index]}}></span>
                    <p>{data[key]}</p>
                </div>
            ));

            index++;
        }
        return legends;
    }
    return<div className="position-relative chart-download-with-heading tree-by-tak-wrap">
        <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title)}>
            <Icon value={'download'} size={'small'} hover={'true'}></Icon>
            <div className="tooltip-wrapper top-tooltip">
                <p class="body-text body-text-2 normal-font ">Download</p>
            </div>
        </div>
        <div className="donwload-chart-ref" ref={exportPngRef}>
            <Box  size={"large"}>
                <div className="task-overview-tree-chart">
                    <div className="top-head-wrap">
                        <Text type={"subtitle-2"} fontWeight={"medium-font"}>{title? title: "By task"}</Text>
                        <div className="chart-info-wrap">
                            {renderLegend()}
                        </div>
                    </div>
                    <div className="task-overview-bars" ref={ref} style={{position:'relative'}}>
                        {tooltip.open ? (
                            <>
                                <Tooltip
                                    ref={tipref}
                                    type={tooltip.tipPosition}
                                    key={Math.random()} // needed for bounds to update correctly
                                    style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                    data={tooltip.tooltipData}
                                    className="tooltipChart"

                                />
                            </>
                        ): (
                            <></>
                        )}
                        {renderTasks()}
                        {tasks.length >4 && !showMore &&
                        <>
                <span className="task-overview-show-more" onClick={()=>{setShowMore(true)}}>
                    +{tasks.length-4} more {tasks.length-4 > 1 ?  "tasks" : "task"}
                </span>
                        </>
                        }
                    </div>
                </div>

            </Box></div></div>;
}