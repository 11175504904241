import SortableTree from "react-sortable-tree";
import { maxLengthCheck } from "../../../../lib/helpers";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { Icon } from "../Icon/Icon";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import {
	addNodeUnderParent,
	getNodeAtPath,
	removeNodeAtPath,
} from "react-sortable-tree";
import { useRef, useState } from "react";
import AutosizeInput from "react-input-autosize";

export const SelectSortableTree = ({search,tree, setTree, formErrors, language, updateCorrectAnswer, formValues, setFormValues})=>{

    const getNodeBg = (extendedNode) => {
        
		if (extendedNode.path.length == 2) {
			return "rgba(150, 255, 252, 0.8)";
		} else if (extendedNode.path.length == 3) {
			return "rgba(150, 255, 252, 0.35)";
		} else if (extendedNode.path.length >= 4) {
			return "rgba(150, 255, 252, 0.2)";
		} else {
			return "rgba(150, 255, 252, 1)";
		}
	};
    const getNodeClass = (extendedNode) => {
        var classNode = (formErrors.answer==extendedNode.node.id) ? "error_class" : '';

        if(extendedNode.node.id == formValues.correct_answer_id){
            classNode += "correct-answer-node";


        }
        return classNode;
    }
    
    return (
        <div className="tree-answer-list-wrap treetest-task-tree" style={{ height: "100%" }}>
            <SortableTree
                
                isVirtualized={false}
                canDrag={false}
                className={"userQTree"}
                searchQuery={search}
                searchMethod={({ node, searchQuery }) =>{ return searchQuery && node.title && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ? true: false; } }
                treeData={tree}
                ignoreCollapsed={false}
                onChange={(tree) => {
                    setTree(tree)
                }}
                generateNodeProps={(extendedNode) => ({
                    className: getNodeClass(extendedNode),
                    style: {
                        background: getNodeBg(extendedNode),
                        width: "calc(100% - "+(44*(extendedNode.path.length-1))+"px)"
                    },
                    title: (
                        <>
                        <span className={`${language == "ar"
                                                        ? "cairo-font"
                                                        : ""
                                                    }`} style={{fontWeight: (extendedNode.path.length > 1) ? '300':'600' }}>
                            {extendedNode.node.title &&
                                <>{extendedNode.node.title}</>
                            }
                            {!extendedNode.node.title &&
                                <>{extendedNode.path.length==1 ? "Level 1" : "This is your child node level "+extendedNode.path.length}</>
                            }
                            
                        </span>
                        {extendedNode.node.id == formValues.correct_answer_id && (
                            <>
                                
                                <button type="button" className="correct-answer">{language == "ar"?"الإجابة الصحيحة":"This is the answer"}</button>
                            </>
                        )}
                        {extendedNode.node.children.length==0 && extendedNode.node.id != formValues.correct_answer_id && (
                            <Button
                                type="button"
                                className="select-correct-answer"
                                onClick={()=>{
                                    
                                        if(updateCorrectAnswer){
                                            updateCorrectAnswer(
                                                extendedNode.node.id,
                                                extendedNode.node.title
                                            )
                                        }
                                    }
                                }
                                label="Select"
                            />
                                
                        )}
                        </>
                    ),
                    buttons: [],
                })}
            />
        </div>
    );
}