import React, { useEffect, useImperativeHandle, useState } from "react";
import { showError } from "../../../../lib/helpers";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { QuestionCard } from "../../../themes/userq/QuestionCard/QuestionCard";
import { Text } from "../../../themes/userq/Text/Text";
import { QuestionInput } from "../../../themes/userq/Question/QuestionInput";
import { Question } from "../../../themes/userq/Question/Question";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _,{ isEmpty } from "lodash"; 
import {EditQuestionInput} from "../../../themes/userq/Question/EditQuestionInput";
import { useRef } from "react";

import { ReactSession } from "../../../../lib/secure_reactsession.js";
import { duplicateQuestion, savePrototypeTestTaskQuestions } from "../../../../services/test";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { useTestBuilderData } from "../contexts/TestBuilderContext.js";

const  FollowupQuestions = React.forwardRef(({objective,maxQuestions, test, task, setIsSaved, followupQuestions, isLoading, updateQuestions, onUpdate, tempAddQuestion},ref)=>{


    let testController = useRef();

    const [duplicateProgress, setDuplicateProgress] = useState(false);

    let duplicateController = useRef();

    const { updateTestLength, getTestLength } = useTestBuilderData();

    const [surveyQuestions, setSurveyQuestions] = useState(followupQuestions);
    const [addingSurveyQuestions, setAddingSurveyQuestions] = useState(objective=="closed"?false:true);
    const [edittingSurveyQuestions, setEdittingSurveyQuestions] = useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});

    const [language, setLanguage] = useState(test.language);
    const [questionType, setQuestionType] = useState("");

    const [initialLoaded, setInitialLoaded] = useState(false);
    
    const [formErrors, setFormErrors] = useState({
        task: null,
        image: null,
        "error_class": null,
    });
    const [formValues, setFormValues] = useState({
        task: "",
        image: null,
        design: null
    });
    const questionRef = useRef(null);

    const editQuestionRef = useRef(null);

    

    useImperativeHandle(ref, () => ({
        questionRef: addingSurveyQuestions ? questionRef.current : null,
        editQuestionRef: edittingSurveyQuestions ? editQuestionRef.current : null
    }),[questionType, tempAddQuestion, edittingSurveyQuestions, addingSurveyQuestions]);

    useEffect(() => {

        setAddingSurveyQuestions(objective=="closed"?false:true)
    
    }, [objective]);

    useEffect(() => {

        if(!isLoading){

            updateQuestions(surveyQuestions);

            savePrototypeQuestions();
            
        }
    }, [surveyQuestions, edittingSurveyQuestions]);

   useEffect(() => {
        
        setSurveyQuestions(followupQuestions);
    }, [followupQuestions.length]);
    

    const savePrototypeQuestions = (questions=null) => {

        /*if(!initialLoaded){

            setInitialLoaded(true);
            
            return true;
        }*/

        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");
        
        setIsSaved({ 'status': 'loading', 'message': '' });
        
        var data = new FormData();

        data.append("task_id", task.id);

        data.append("test_id", test.id);

        //let questions_local = questions ? questions :  surveyQuestions;
        
        let questions_local = surveyQuestions;
        

        questions_local.forEach((item) => {
            data.append("survey_questions[]", JSON.stringify(item));
        });

        savePrototypeTestTaskQuestions(data, token, testController.current?.signal).then((response) => {
            
            if (response.success) {

                getTestLength(test.id);
                setIsSaved({ 'status': 'success', 'message': '' });

            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
            }
        });

    };
    const cancelEditQuestionHandler = () => {
        //alert(1)
        setEdittingSurveyQuestions(false);
        setEditQuestionId(null);
    };
    const removequestion = (index) => {
        //alert(2)
        const list = [...surveyQuestions];
        list.splice(index, 1);
        setSurveyQuestions(list);
        if (list.length == 0) {
            if(objective=="closed"){
            setAddingSurveyQuestions(false);
            } else {
                setAddingSurveyQuestions(true);
            }
        }

        setEdittingSurveyQuestions(false);
    
    };

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    const duplicatequestion = (index) => {
        if (!edittingSurveyQuestions) {
            if(!duplicateProgress && surveyQuestions.length<3){

                let list = [...surveyQuestions];
                // let question = Object.assign({},surveyQuestions[index]);
                let question = _.cloneDeep(surveyQuestions[index]);
                // list.push(question);
                if (duplicateController.current) {
                    duplicateController.current.abort();
        
                }
                const controller = new AbortController();
        
                duplicateController.current = controller;

                setDuplicateProgress(true);
                
                setIsSaved({ 'status': 'loading', 'message': '' });
                
                let token = ReactSession.get("token");

                var data = {};

                data.test_id = test.id;

                data.question = question;

                duplicateQuestion(data, token, duplicateController.current?.signal).then((response) => {

                    setDuplicateProgress(false);

                    if (response.success) {

                        setIsSaved({ 'status': 'success', 'message': '' });
                    
                        list.splice(index, 0, response.question);
                        setSurveyQuestions(list);

                        //scrolling to duplicated question

                        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight+document.querySelector('.three-column-layout-top-header').offsetHeight;
                        var offsetTop = Position(document.getElementById("ques-"+(index)));
                        console.log(offsetTop - fixedElementHeight);
                        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

                        setTimeout(function () {
                            var duplicateQuestionElement = document.getElementById("question-"+(index+1));
                            //console.log(duplicateQuestionElement)
                            if(duplicateQuestionElement){

                                duplicateQuestionElement.classList.add("question-duplicate-animation");

                                setTimeout(function () {

                                    duplicateQuestionElement.classList.remove("question-duplicate-animation");
                                    // console.log(duplicateQuestionElement);
                                }, 5000)
                            }
                        }, 100)
                            
                    } else {
                        setIsSaved({ 'status': 'error', 'message': response.message });
                    }
                });
                

            }else{
                showError("You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones.");
            }
        } else {
            showError("Finish editing question");
        }
    };

    const edittingQuestionsHandler = (index) => {
        if (!edittingSurveyQuestions) {
            setEdittingSurveyQuestions(true);
            setEditQuestionId(index);
        } else {
            showError("Finish editing earlier question.");
        }
    };
    const cancelQuestionHandler = () => {
        if(objective=="closed"){
            setEdittingSurveyQuestions(false)
            setAddingSurveyQuestions(false);
        }
        if (surveyQuestions.length != 0) {
            /*if(objective=="closed"){
            setAddingSurveyQuestions(false);
            } else {
                setAddingSurveyQuestions(true);
            }*/
        }
        setQuestionType("");
    };
    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
             border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
             border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };
    const toggleLogicHandler = (logicstatus, questionindex) => {
        surveyQuestions[questionindex]['is_logic'] = logicstatus;
        surveyQuestions[questionindex]['jumpTo'] = [];
        var jumptolist = [];
        for (var i = 0; i < surveyQuestions[questionindex]['options'].length; i++) {
            jumptolist.push(questionindex + 1 < surveyQuestions.length ? questionindex + 2 : "End Survey");
        }
        surveyQuestions[questionindex]['jumpTo'] = jumptolist;

        surveyQuestions.splice(
            questionindex,
            1,
            surveyQuestions[questionindex]
        );
        setSurveyQuestions([
            ...surveyQuestions,
        ]);
    };
    const saveOptionJumpToHandler = (optionindex, value, questionindex) => {
        surveyQuestions[questionindex]['jumpTo'][optionindex] = value;
        surveyQuestions.splice(
            questionindex,
            1,
            surveyQuestions[questionindex]
        );
        setSurveyQuestions([
            ...surveyQuestions,
        ]);
    };
    const noOfOptional = (qindex)=>{
        var no_of_optional = 0;
        
        surveyQuestions.forEach(function(item, index){
            if(item.is_optional && qindex!=index){
                no_of_optional++;
            }
        });
        
        
        return no_of_optional;
    }
    
    const checkIsOptionalNotAllowedInNew = ()=>{
        var no_of_optional = 0;
        
        surveyQuestions.forEach(function(item){
            if(item.is_optional){
                no_of_optional++;
            }
        });
        
        if(no_of_optional==surveyQuestions.length){
            
            return true;
        }
        if(surveyQuestions.length==0){
            return true;
        }
        return false;
    }
    return (
        <div className="followup-questions-container"> 
            {isLoading &&
            <div className="test-section-loader"> <LoadingIcon /></div>
            }
            {!isLoading &&
            <>
            <div className="formbox"  id="questions">
                <Text type={'subtitle-2'} fontWeight={'medium-font'} >
                    {
                    objective=="closed"
                    ?
                    <>Follow up question <span className="optional">(Optional)</span></>
                    :
                    <>Questions</>
                    }
                </Text>
                <Text
                    type={'body-text-2'}
                    fontWeight={'medium-font'}
                >
                    {objective=="closed"
                    ?
                    <>Include the question you want to ask the participants about your design. We recommend adding an open-ended question to understand what they can recall or what captured their attention.</>
                    :
                    <>Add up to 3 questions to ask the participants while they are exploring your prototype</>
                    }
                    
                </Text>
            </div>
            
            
            <div className="formbox">
                {surveyQuestions.length === 0 ? (
                <div className="nodata-wrap"></div>
                ) : (
                    <div className="added-question-wrap mb-32"
                    style={{ position: 'relative' }}>
                    <DragDropContext
                        onDragEnd={(param) => {
                            setPlaceholderProps({});
                            if (edittingSurveyQuestions) {
                                showError("Finish editing your question first.");
                            } else {
                                const srcI = param.source.index;
                                const desI = param.destination.index;
                                surveyQuestions.splice(
                                    desI,
                                    0,
                                    surveyQuestions.splice(srcI, 1)[0]
                                );
                                savePrototypeQuestions(true,false);
                            }
                        }}
                        onDragStart={handleDragStart}
                        onDragUpdate={handleDragUpdate}
                    >
                        <Droppable droppableId="droppable-1" isCombineEnabled>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {surveyQuestions.map((question, index) => (
                                        <div
                                            key={index}
                                            className="survey-added-question-repeat"
                                        >
                                            <Draggable
                                                draggableId={"draggable-" + index}
                                                index={index}
                                                isDragDisabled={objective=="closed"?true:false}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={
                                                            snapshot.isDragging
                                                                ? "question-answers-dragging draggable-element-start"
                                                                : ""
                                                        }
                                                    >
                                                        {editQuestionId === index ? (
                                                            <></>
                                                        ) : (
                                                            <div
                                                                key={index}
                                                                id={'question-'+index}
                                                                className={`survey-question-preview ${snapshot.isDragging?'draggable-inner-element-start':'grey-bg'}`}
                                                            >
                                                                <div
                                                                    className={`question-answer-holder ${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                                                                    <div {...provided.dragHandleProps}>
                                                                        <Question
                                                                            duplicateProgress={duplicateProgress}
                                                                            index={index}
                                                                            question={question}
                                                                            hideDuplicateAction={objective=="closed"?true:false}
                                                                            onCopy={() => duplicatequestion(index)}
                                                                            onEdit={() => edittingQuestionsHandler(index)}
                                                                            onDelete={() => removequestion(index)}
                                                                            allQuestions={surveyQuestions}
                                                                            saveJumpTo={(optionindex, value) => saveOptionJumpToHandler(optionindex, value, index)}
                                                                            onToggleLogic={(logicstatus, indexQ) => toggleLogicHandler(logicstatus, indexQ)}
                                                                            language={test.language}
                                                                        />
                                                                    </div>
                                                                    <div id={"ques-"+index}></div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                            <div
                                                className={`question-edit ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
                                                {edittingSurveyQuestions &&
                                                    editQuestionId === index && (
                                                        <>
                                                        
                                                        <EditQuestionInput
                                                            questionSection="prototype_test"
                                                            id_prefix={"edit_question"+task.id}
                                                            ref={editQuestionRef}
                                                            questionDetails={
                                                                surveyQuestions[index]
                                                            }
                                                            checkOptionalCondition={false}
                                                            noOfOptional={noOfOptional(index)}
                                                            totalQuestions={surveyQuestions.length}
                                                            cancelEditQuestion={
                                                                cancelEditQuestionHandler
                                                            }
                                                            editQuestions={(question) => {
                                                                
                                                                surveyQuestions.splice(
                                                                    index,
                                                                    1,
                                                                    question
                                                                );
                                                                setSurveyQuestions([
                                                                    ...surveyQuestions,
                                                                ]);
                                                                savePrototypeQuestions([
                                                                    ...surveyQuestions,
                                                                ]);
                                                            }}
                                                            language={language}
                                                            other={
                                                                test.language == "en" ?
                                                                    question.questionType == "free"
                                                                        ? "Other"
                                                                        : surveyQuestions[
                                                                            index
                                                                        ].options.slice(-1) == "Other"
                                                                            ? true
                                                                            : false : question.questionType == "free"
                                                                        ? "آخر"
                                                                        : surveyQuestions[
                                                                            index
                                                                        ].options.slice(-1) == "آخر"
                                                                            ? true
                                                                            : false
                                                            }
                                                            questionno={index + 1}
                                                            questionlist={surveyQuestions}
                                                            changeQuestionType={(questionType) => {
                                                                surveyQuestions[index].questionType = questionType
                                                            }}
                                                            addLikertSubquestions={() => {
                                                                surveyQuestions[index].subQuestions = [""]
                                                            }}
                                                            islogic={false}
                                                            onDelete={()=>removequestion(index) }
                                                            onUpdate={(question)=>{
                            
                                                                if(onUpdate){
                                                                    onUpdate(question);
                                                                }
                                                            }}
                                                        />
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                    {provided.placeholder}
                                    {!isEmpty(placeholderProps) && (
                                        <div
                                            className="placeholder"
                                            style={{
                                                top: placeholderProps.clientY,
                                                left: placeholderProps.clientX,
                                                height: placeholderProps.clientHeight,
                                                width: placeholderProps.clientWidth,
                                                position: "absolute",
                                                borderColor: "#000000",
                                                background:placeholderProps.background,
                                                borderRadius:'10px',
                                                border:placeholderProps.border,
                                                margin: '-15px 0px 10px'
                                            }}
                                        />
                                    )}

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                )}
                {console.log(edittingSurveyQuestions)}
                {!edittingSurveyQuestions && surveyQuestions.length < maxQuestions && 
                    (!addingSurveyQuestions ? (
                        <Button
                            iconLeft={<Icon value="add" />}
                            label="Add question"
                            size={"medium"}
                            type={'secondary'}
                            onClick={() => {
                                if (surveyQuestions.length < maxQuestions) {
                                    setAddingSurveyQuestions(true);
                                    
                                } else {
                                    showError("You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones.")

                                }
                            }}
                        />

                    ) : (
                        <></>
                    ))}
                {(addingSurveyQuestions && questionType === "") ? (
                <div
                    className={`ans-list-wrap question-prev-wrap  survey-test-option-wrap mb-0 `}>
                    {surveyQuestions.length<maxQuestions && <>
                    {surveyQuestions.length>0 &&
                    <div className="add-more-question-heading"><Text type="body-text-2" fontWeight="medium">Add more question(s)</Text></div>}
                    <div className={"add-more-question-option"}>
                        <div className="stud-tiles-hold">
                            <QuestionCard
                                questiontype="Free Text"
                                onClick={() => {
                                    setQuestionType("free");
                                    setEdittingSurveyQuestions(true);
                                }}
                            />
                            <QuestionCard
                                questiontype="Single choice"
                                onClick={() => {
                                    setQuestionType("singlechoice");
                                    setEdittingSurveyQuestions(true);
                                }}
                            />
                            <QuestionCard
                                questiontype="Multiple-choice"
                                onClick={() => {
                                    setQuestionType("multiplechoice");
                                    setEdittingSurveyQuestions(true);
                                }}
                            />
                            <QuestionCard
                                questiontype="Likert scale"
                                onClick={() => {
                                    setQuestionType("likertscale");
                                    setEdittingSurveyQuestions(true);
                                }}
                            />
                            <QuestionCard
                                questiontype="Rating scale"
                                onClick={() => {
                                    setQuestionType("ratingscale");
                                    setEdittingSurveyQuestions(true);
                                }}
                            />
                            <QuestionCard
                                questiontype="Ranking scale"
                                onClick={() => {
                                    setQuestionType("rankingscale");
                                    setEdittingSurveyQuestions(true);
                                }}
                            />
                        </div>

                    </div>
                    </>}

                    

                </div>
                ) : (

                <div
                    className={`${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                    {/*checkIsOptionalNotAllowedInNew() */}
                    <QuestionInput
                        id_prefix={"add_question"+task.id}
                        ref={questionRef}
                        isOptionalNotAllowed={false}
                        questionType={questionType}
                        cancelQuestion={cancelQuestionHandler}
                        addQuestions={(question) => {
                            setSurveyQuestions([...surveyQuestions, question]);
                            setAddingSurveyQuestions(true);
                            setQuestionType("");
                            setEdittingSurveyQuestions(false);

                            savePrototypeQuestions([...surveyQuestions, question]);
                        }}
                        language={language}
                        questionno={surveyQuestions.length + 1}
                        questionlist={surveyQuestions}
                        changeQuestionType={(questionType) => {
                            setQuestionType(questionType)
                        }}
                        islogic={false}
                        onUpdate={(question)=>{
                            
                            if(onUpdate){
                                onUpdate(question);
                            }
                        }}
                    />

                </div>
                )}
                {formErrors.questions != null && (
                <span className={formErrors.error_class+" followup-question-error"}>
                    {formErrors.questions}
                </span>
                )}
            </div>
            </>
            }
                
                
            
        </div>
    );
});
export default  FollowupQuestions;