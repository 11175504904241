export const nationalities_other = [{
    "value": "Afghan \/ Afghani",
    "label": "Afghan \/ Afghani",
    "code":"AF"
}, {
    "value": "Albanian",
    "label": "Albanian",
    "code":"AL"
}, {
    "value": "Algerian",
    "label": "Algerian",
    "code":"DZ"
}, {
    "value": "American",
    "label": "American",
    "code":"US"
}, {
    "value": "Angolan",
    "label": "Angolan",
    "code":"AO"

}, {
    "value": "Argentine \/ Argentinian",
    "label": "Argentine \/ Argentinian",
    "code":"AR"
}, {
    "value": "Australian",
    "label": "Australian",
    "code":"AU"
}, {
    "value": "Austrian",
    "label": "Austrian",
    "code":"AT"
}, {
    "value": "Bangladeshi",
    "label": "Bangladeshi",
    "code":"BD"
}, {
    "value": "Bahraini",
    "label": "Bahraini",
    "code":"BH"
}, {
    "value": "Belarusian",
    "label": "Belarusian",
    "code":"BY"
}, {
    "value": "Belgian",
    "label": "Belgian",
    "code":"BE"
}, {
    "value": "Bhutanese",
    "label": "Bhutanese",
    "code":"BT"
}, {
    "value": "Bolivian",
    "label": "Bolivian",
    "code":"BO"
}, {
    "value": "Botswanan",
    "label": "Botswanan",
    "code":"BW"
}, {
    "value": "Brazilian",
    "label": "Brazilian",
    "code":"BR"
}, {
    "value": "British",
    "label": "British",
    "code":"GB"
}, {
    "value": "Bulgarian",
    "label": "Bulgarian",
    "code":"BG"
}, {
    "value": "Burmese",
    "label": "Burmese",
    "code":"MM"
}, {
    "value": "Cambodian",
    "label": "Cambodian",
    "code":"KH"
}, {
    "value": "Canadian",
    "label": "Canadian",
    "code":"CA"
}, {
    "value": "Chilean",
    "label": "Chilean",
    "code":"CL"
}, {
    "value": "Chinese",
    "label": "Chinese",
    "code":"CN"
}, {
    "value": "Colombian",
    "label": "Colombian",
    "code":"CO"
}, {
    "value": "Congolese",
    "label": "Congolese",
    "code":"CD"
}, {
    "value": "Croatian",
    "label": "Croatian",
    "code":"HR"
}, {
    "value": "Cuban",
    "label": "Cuban",
    "code":"CU"
}, {
    "value": "Cypriot",
    "label": "Cypriot",
    "code":"CY"
}, {
    "value": "Czech",
    "label": "Czech",
    "code":"CZ"
}, {
    "value": "Danish",
    "label": "Danish",
    "code":"DK"
}, {
    "value": "Dutch",
    "label": "Dutch",
    "code":"NL"
}, {
    "value": "Ecuadorian",
    "label": "Ecuadorian",
    "code":"EC"
}, {
    "value": "Egyptian",
    "label": "Egyptian",
    "code":"EG"
}, {
    "value": "Estonian",
    "label": "Estonian",
    "code":"EE"
}, {
    "value": "Ethiopian",
    "label": "Ethiopian",
    "code":"ET"
}, {
    "value": "Emirati",
    "label": "Emirati",
    "code":"AE"
}, {
    "value": "Fijian",
    "label": "Fijian",
    "code":"FJ"
}, {
    "value": "Filipino",
    "label": "Filipino",
    "code":"PH"
}, {
    "value": "Finnish",
    "label": "Finnish",
    "code":"FI"
}, {
    "value": "French",
    "label": "French",
    "code":"FR"
}, {
    "value": "Georgian",
    "label": "Georgian",
    "code":"GE"
}, {
    "value": "German",
    "label": "German",
    "code":"DE"
}, {
    "value": "Ghanaian",
    "label": "Ghanaian",
    "code":"GH"
}, {
    "value": "Greek",
    "label": "Greek",
    "code":"GR"
}, {
    "value": "Guatemalan",
    "label": "Guatemalan",
    "code":"GT"
}, {
    "value": "Hong Kongese",
    "label": "Hong Kongese",
    "code":"HK"
}, {
    "value": "Hungarian",
    "label": "Hungarian",
    "code":"HU"
}, {
    "value": "Icelandic",
    "label": "Icelandic",
    "code":"IS"
}, {
    "value": "Indian",
    "label": "Indian",
    "code":"IN"
}, {
    "value": "Indonesian",
    "label": "Indonesian",
    "code":"ID"
}, {
    "value": "Iranian",
    "label": "Iranian",
    "code":"IR"
}, {
    "value": "Iraqi",
    "label": "Iraqi",
    "code":"IQ"
}, {
    "value": "Irish",
    "label": "Irish",
    "code":"IE"
}, {
    "value": "Israeli",
    "label": "Israeli",
    "code":"IL"
}, {
    "value": "Italian",
    "label": "Italian",
    "code":"IT"
}, {
    "value": "Ivorian",
    "label": "Ivorian",
    "code":"CI"
}, {
    "value": "Jamaican",
    "label": "Jamaican",
    "code":"JM"
}, {
    "value": "Japanese",
    "label": "Japanese",
    "code":"JP"
}, {
    "value": "Jordanian",
    "label": "Jordanian",
    "code":"JO"
}, {
    "value": "Kazakh \/ Kazakhstani",
    "label": "Kazakh \/ Kazakhstani",
    "code":"KZ"
}, {
    "value": "Kenyan",
    "label": "Kenyan",
    "code":"KE"
}, {
    "value": "Kuwaiti",
    "label": "Kuwaiti",
    "code":"KW"
}, {
    "value": "Laotian \/ Lao",
    "label": "Laotian \/ Lao",
    "code":"LA"
}, {
    "value": "Latvian",
    "label": "Latvian",
    "code":"LV"
}, {
    "value": "Lebanese",
    "label": "Lebanese",
    "code":"LB"
}, {
    "value": "Libyan",
    "label": "Libyan",
    "code":"LY"
}, {
    "value": "Lithuanian",
    "label": "Lithuanian",
    "code":"LT"
}, {
    "value": "Malagasy",
    "label": "Malagasy",
    "code":"MG"
}, {
    "value": "Malaysian",
    "label": "Malaysian",
    "code":"MY"
}, {
    "value": "Maldivian",
    "label": "Maldivian",
    "code":"MV"
}, {
    "value": "Mexican",
    "label": "Mexican",
    "code":"MX"
}, {
    "value": "Mongolian",
    "label": "Mongolian",
    "code":"MN"
}, {
    "value": "Moroccan",
    "label": "Moroccan",
    "code":"MA"
}, {
    "value": "Mozambican",
    "label": "Mozambican",
    "code":"MZ"
}, {
    "value": "Namibian",
    "label": "Namibian",
    "code":"NA"
}, {
    "value": "Nepalese \/ Nepali",
    "label": "Nepalese \/ Nepali",
    "code":"NP"
}, {
    "value": "New Zealand",
    "label": "New Zealand",
    "code":"NZ"
}, {
    "value": "Nigerian",
    "label": "Nigerian",
    "code":"NG"
}, {
    "value": "North Korean",
    "label": "North Korean",
    "code":"KP"
}, {
    "value": "Norwegian",
    "label": "Norwegian",
    "code":"NO"
}, {
    "value": "Pakistani",
    "label": "Pakistani",
    "code":"PK"
}, {
    "value": "Palestinian",
    "label": "Palestinian",
    "code":"PS"
}, {
    "value": "Paraguayan",
    "label": "Paraguayan",
    "code":"PY"
}, {
    "value": "Peruvian",
    "label": "Peruvian",
    "code":"PE"
},{
    "value": "Polish",
    "label": "Polish",
    "code":"PL"
},{
    "value": "Portuguese",
    "label": "Portuguese",
    "code":"PT"
}, {
    "value": "Romanian",
    "label": "Romanian",
    "code":"RO"
}, {
    "value": "Russian",
    "label": "Russian",
    "code":"RU"
}, {
    "value": "Saudi Arabian",
    "label": "Saudi Arabian",
    "code":"SA"
}, {
    "value": "Serbian",
    "label": "Serbian",
    "code":"RS"
}, {
    "value": "Singaporean",
    "label": "Singaporean",
    "code":"SG"
}, {
    "value": "Slovak \/ Slovakian",
    "label": "Slovak \/ Slovakian",
    "code":"SK"
}, {
    "value": "Slovenian \/ Slovene",
    "label": "Slovenian \/ Slovene",
    "code":"SI"
}, {
    "value": "Somali \/ Somalian",
    "label": "Somali \/ Somalian",
    "code":"SO"
}, {
    "value": "South African",
    "label": "South African",
    "code":"ZA"
}, {
    "value": "South Korean",
    "label": "South Korean",
    "code":"KR"
}, {
    "value": "Spanish",
    "label": "Spanish",
    "code":"ES"
}, {
    "value": "Sri Lankan",
    "label": "Sri Lankan",
    "code":"LK"
}, {
    "value": "Sudanese",
    "label": "Sudanese",
    "code":"SD"
}, {
    "value": "Swedish",
    "label": "Swedish",
    "code":"SE"
}, {
    "value": "Swiss",
    "label": "Swiss",
    "code":"CH"
}, {
    "value": "Syrian",
    "label": "Syrian",
    "code":"SY"
}, {
    "value": "Taiwanese",
    "label": "Taiwanese",
    "code":"TW"
}, {
    "value": "Tanzanian",
    "label": "Tanzanian",
    "code":"TZ"
}, {
    "value": "Thai",
    "label": "Thai",
    "code":"TH"
}, {
    "value": "Tunisian",
    "label": "Tunisian",
    "code":"TN"
}, {
    "value": "Turkish",
    "label": "Turkish",
    "code":"TR"
}, {
    "value": "Ugandan",
    "label": "Ugandan",
    "code":"UG"
}, {
    "value": "Ukrainian",
    "label": "Ukrainian",
    "code":"UA"
}, {
    "value": "Uruguayan",
    "label": "Uruguayan",
    "code":"UY"
}, {
    "value": "Venezuelan",
    "label": "Venezuelan",
    "code":"VE"
}, {
    "value": "Vietnamese",
    "label": "Vietnamese",
    "code":"VN"
}, {
    "value": "Yemeni \/ Yemenite",
    "label": "Yemeni \/ Yemenite",
    "code":"YE"
}, {
    "value": "Zambian",
    "label": "Zambian",
    "code":"ZM"
}, {
    "value": "Qatari",
    "label": "Qatari",
    "code":"QA"
}, {
    "value": "Omani",
    "label": "Omani",
    "code":"OM"
}];
export const nationalities_other_old = [
    {
        "value": "Afghan \/ Afghani",
        "label": "Afghan \/ Afghani",
        "code":"AF"
    },
    {
        "value": "American",
        "label": "American",
        "code":"US"
    },
    {
        "value": "Angolan",
        "label": "Angolan",
        "code":"AO"
    },
    {
        "value": "Argentine \/ Argentinian",
        "label": "Argentine \/ Argentinian",
        "code":"AR"
    },
    {
        "value": "Bolivian",
        "label": "Bolivian",
        "code":"BO"
    },
    {
        "value": "Botswanan",
        "label": "Botswanan",
        "code":"BW"

    },
    {
        "value": "Brazilian",
        "label": "Brazilian",
        "code":"BR"
    },
    {
        "value": "Burmese",
        "label": "Burmese",
        "code":"MM"
    },
    {
        "value": "Cambodian",
        "label": "Cambodian",
        "code":"KH"

    },
    {
        "value": "Canadian",
        "label": "Canadian",
        "code":"CA"

    },
    {
        "value": "Chilean",
        "label": "Chilean",
        "code":"CL"
    },
    {
        "value": "Colombian",
        "label": "Colombian",
        "code":"CO"
    },
    {
        "value": "Congolese",
        "label": "Congolese",
        "code":"CD"
    },
    {
        "value": "Cuban",
        "label": "Cuban",
        "code":"CU"
    },
    {
        "value": "Ecuadorian",
        "label": "Ecuadorian",
        "code":"EC"

    },
    {
        "value": "Ethiopian",
        "label": "Ethiopian",
        "code":"ET"
    },
    {
        "value": "Georgian",
        "label": "Georgian",
        "code":"GE"
    },
    {
        "value": "Ghanaian",
        "label": "Ghanaian",
        "code":"GH"
    },
    {
        "value": "Guatemalan",
        "label": "Guatemalan",
        "code":"GT"
    },
    {
        "value": "Iranian",
        "label": "Iranian",
        "code":"IR"
    },
    {
        "value": "Iraqi",
        "label": "Iraqi",
        "code":"IQ"
    },
    {
        "value": "Israeli",
        "label": "Israeli",
        "code":"IL"
    },
    {
        "value": "Ivorian",
        "label": "Ivorian",
        "code":"CI"
    },
    {
        "value": "Jamaican",
        "label": "Jamaican",
        "code":"JM"

    },
    {
        "value": "Jordanian",
        "label": "Jordanian",
        "code":"JO"
    },
    {
        "value": "Kazakh \/ Kazakhstani",
        "label": "Kazakh \/ Kazakhstani",
        "code":"KZ"
    },
    {
        "value": "Kenyan",
        "label": "Kenyan",
        "code":"KE"
    },
    {
        "value": "Laotian \/ Lao",
        "label": "Laotian \/ Lao",
        "code":"LA"
    },
    {
        "value": "Libyan",
        "label": "Libyan",
        "code":"LY"
    },
    {
        "value": "Malagasy",
        "label": "Malagasy",
        "code":"MG"

    },
    {
        "value": "Mexican",
        "label": "Mexican",
        "code":"MX"
    },
    {
        "value": "Mongolian",
        "label": "Mongolian",
        "code":"MN"
    },
    {
        "value": "Mozambican",
        "label": "Mozambican",
        "code":"MZ"
    },
    {
        "value": "Namibian",
        "label": "Namibian",
        "code":"NA"
    },
    {
        "value": "Nigerian",
        "label": "Nigerian",
        "code":"NG"
    },
    {
        "value": "Palestinian",
        "label": "Palestinian",
        "code":"PS"

    },
    {
        "value": "Paraguayan",
        "label": "Paraguayan",
        "code":"PY"
    },
    {
        "value": "Peruvian",
        "label": "Peruvian",
        "code":"PE"
    },
    {
        "value": "Somali \/ Somalian",
        "label": "Somali \/ Somalian",
        "code":"SO"
    },
    {
        "value": "South African",
        "label": "South African",
        "code":"ZA"
    },
    {
        "value": "Sudanese",
        "label": "Sudanese",
        "code":"SD"

    },
    {
        "value": "Tanzanian",
        "label": "Tanzanian",
        "code":"TZ"
    },
    {
        "value": "Turkish",
        "label": "Turkish",
        "code":"TR"
    },
    {
        "value": "Ugandan",
        "label": "Ugandan",
        "code":"UG"
    },
    {
        "value": "Uruguayan",
        "label": "Uruguayan",
        "code":"UY"
    },
    {
        "value": "Venezuelan",
        "label": "Venezuelan",
        "code":"VE"
    },
    {
        "value": "Zambian",
        "label": "Zambian",
        "code":"ZM"
    }];