import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Text } from './../Text/Text';
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import {ReactSession} from "../../../../lib/secure_reactsession";
import {getProfile} from "../../../../services/user";
import {getOnfidoParams, uploadAnotherDocumentOnfidoResult,getUploadAnotherOnfidoParams, updateOnfidoTesterProfile} from "../../../../services/id_verification";
import moment from "moment";
import {showToast} from "../../../../lib/helpers";
import {LoadingIcon} from "../../../loader/loadingIconNew";
import {init} from "onfido-sdk-ui";
var onfido = {};

export default function DocumentNotMatch({
    openModal,
    confirm_title,
    confirm_btn_title,
    cancel_btn_title,
    close,
    confirmHandler,
}) {
    const closeModal = () => {
        close();
    };
    const wrapperRef = useRef(null);

    const navigate = useNavigate();

    const [documentNotMatchLoading, setDocumentNotMatchLoading] = useState(false);

    const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [existingData, setExistingData] = useState({});
    const [idVerificationData, setIdVerificationData] = useState({
        first_name: "",
        last_name: "",
        date_of_birth: "",
        nationality: "",
        gender: "",
    });

    const getUserProfileData = ()=>{
        let token = ReactSession.get("token");

        setDocumentNotMatchLoading(true);

        getProfile(token).then((response) => {

            setDocumentNotMatchLoading(false);

            if (response.success) {

                setExistingData({
                            first_name: (response.data.user.first_name).toLowerCase(),
                            last_name: (response.data.user.last_name).toLowerCase(),
                            date_of_birth: response.data.user.date_of_birth
                                ? new Date(
                                    moment(response.data.user.date_of_birth).format()
                                )
                                : "",
                            nationality: response.data.user.nationality,
                            gender:
                                response.data.user.gender != null
                                    ? response.data.user.gender
                                    : "male",

                        }
                );

                if(response.data.user.testerIdVerificationData){
                    setIdVerificationData({
                            first_name: (response.data.user.testerIdVerificationData.first_name).toLowerCase(),
                            last_name: (response.data.user.testerIdVerificationData.last_name).toLowerCase(),
                            date_of_birth: response.data.user.testerIdVerificationData.date_of_birth
                                ? moment(response.data.user.testerIdVerificationData.date_of_birth).format('YYYY-MM-DD')
                                : "",
                            nationality: response.data.user.testerIdVerificationData.nationality,
                            gender:
                                response.data.user.testerIdVerificationData.gender != null
                                    ? response.data.user.testerIdVerificationData.gender
                                    : "",

                        }
                    );
                }





            } else {

                showToast(response.message,'error')

            }
        });
    }

    useEffect(()=>{
        if(openModal)
            getUserProfileData();
    },[openModal])

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up 
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const updateOnfidoProfileData = ()=>{


        if (!formLoading) {

            setFormLoading(true);

            const token = ReactSession.get("token");


            let data = Object.assign({})
            data["first_name"] = idVerificationData.first_name;
            data["last_name"] = idVerificationData.last_name;
            data["date_of_birth"] = idVerificationData.date_of_birth;
            data["nationality"] = idVerificationData.nationality;
            data["gender"] = idVerificationData.gender;


            updateOnfidoTesterProfile(data, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                     let user = ReactSession.get("user");
                    //
                     user.profile_verification_status = 'verified';
                    //
                     ReactSession.set("user", user);

                    showToast(response.message,'success');

                    closeModal();
                    confirmHandler();

                } else {

                    showToast(response.message,'error');

                }
            });
        }
    }

    const getOnfidoParamsApi = () => {

        closeModal();
        setUploadDocumentLoading(true);

        const token = ReactSession.get("token");


        getUploadAnotherOnfidoParams(token)
            .then(response => {
                setUploadDocumentLoading(false);

                if (response.success) {
                    onfido = init({
                        token: response.data.sdk_token,
                        useModal: true,
                        isModalOpen: true,
                        onModalRequestClose: function() {
                            //alert(1)
                            // Update options with the state of the modal
                            onfido.setOptions({isModalOpen: false})
                        },
                        onComplete: function (data) {
                            uploadAnotherDocumentHandler();

                        },
                        workflowRunId: response.data.workflow_run_id,
                    })

                } else {

                    showToast(response.message,'error');
                }
            })


    }

    const uploadAnotherDocumentHandler = ()=>{

        setUploadDocumentLoading(true);

        const token = ReactSession.get("token");

        uploadAnotherDocumentOnfidoResult(token)
            .then(response => {
                setUploadDocumentLoading(false);

                if (response.success) {

                    onfido.setOptions({isModalOpen: false})

                    // console.log(response.data)
                    if(response.data.user) {


                        let user = ReactSession.get("user");

                        user.profile_verification_status = response.data.user.profile_verification_status;
                        if(response.data.user.first_name){
                            user.first_name = response.data.user.first_name;
                        }
                        if(response.data.user.last_name){
                            user.last_name = response.data.user.last_name;
                        }

                        user.profile_verified_at = (response.last_attempt)?response.last_attempt:'';
                        ReactSession.set("user", user);

                        if(response.data.user.profile_verification_status == 'verified'){

                            showToast('Your ID document has been validated and your identity is now verified','success');

                            confirmHandler();

                        }else if(response.data.user.profile_verification_status == "duplicate_identity") {

                            confirmHandler(true);
                            // navigate("/tester/profile/duplicate_identity");

                        } else if(response.data.user.profile_verification_status == "minor") {

                            confirmHandler(true);
                            // navigate("/tester/profile/under_age");

                        } else if(response.data.user.profile_verification_status =="expired_document") {

                            confirmHandler();

                        }else if(response.data.user.profile_verification_status =="invalid_document") {

                            confirmHandler();

                        }else if(response.data.user.profile_verification_status =="id_not_matched_profile") {

                            ReactSession.set("document_not_matched_model","true");
                            confirmHandler();
                        }else{

                            showToast(response.message,'error')
                        }




                    }

                } else {
                    showToast(response.message,'error');
                }
            })

    }

    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap document_not_match_modal"
        >
            <Modal.Body className="p-32" ref={wrapperRef}>
                    {documentNotMatchLoading ? (
                        <div class="accountloading-box"> <LoadingIcon /></div>
                    ):
                    <div className="modal-inner-text">
                        <div className="modal-header-top mb-40 mt-0">
                            <h2 className="heading h2 text-center mt-0">{confirm_title}</h2>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal(); 
                                }}
                                aria-label="Close" 
                            >
                            </button>
                        </div>
                        
                         <div className="document_table_data">
                            <div className="table-responsive">
                                <table className="w-100"> 
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th><Text type="body-text-2" fontWeight={"medium-font"} cssClasses={"color-black"}><Icon value="profile" size={'medium'}/> Profile info</Text></th>
                                                <th><Text type="body-text-2" fontWeight={"medium-font"} cssClasses={"color-black"}><Icon value="id" size={'medium'}/> ID Document</Text></th>
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={"gray-color"}>
                                                        First name
                                                    </Text>
                                                </td>
                                                <td>
                                                    <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${existingData.first_name === idVerificationData.first_name?'gray-color-500':"color-black  info-not-match"}`}>
                                                        {existingData.first_name}
                                                    </Text>
                                                </td>
                                                <td>
                                                    <div className="doc_icon_info">
                                                        <Text type="body-text-2" fontWeight={"medium-font"}  cssClasses={`${existingData.first_name === idVerificationData.first_name?'gray-color-500':"color-black"}`}>
                                                            {idVerificationData.first_name}
                                                        </Text>
                                                        {existingData.first_name === idVerificationData.first_name ? <Icon value={"verified"} colorClass={'success-200-svg'}/>:<Icon value={"error"} colorClass={'danger-200-svg'}/>}

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Text type="body-text-2" fontWeight={"medium-font"}   cssClasses={"gray-color"}>
                                                        Last name
                                                    </Text>
                                                </td>
                                                <td>
                                                    <Text type="body-text-2" fontWeight={"medium-font"}  cssClasses={`${existingData.last_name === idVerificationData.last_name?'gray-color-500':"color-black  info-not-match"}`}>
                                                        {existingData.last_name}
                                                    </Text>
                                                </td>
                                                <td>
                                                    <div className="doc_icon_info">
                                                        <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${existingData.last_name === idVerificationData.last_name?'gray-color-500':"color-black"}`}>
                                                            {idVerificationData.last_name}
                                                        </Text>
                                                        {existingData.last_name === idVerificationData.last_name ? <Icon value={"verified"} colorClass={'success-200-svg'}/>:<Icon value={"error"} colorClass={'danger-200-svg'}/>}
                                                    </div>
                                                </td>
                                            </tr>
                                            {idVerificationData.date_of_birth &&
                                                <tr>
                                                    <td>
                                                        <Text type="body-text-2" fontWeight={"medium-font"}
                                                              cssClasses={"gray-color"}>
                                                            Date of birth
                                                        </Text>
                                                    </td>
                                                    <td>
                                                        <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${moment(existingData.date_of_birth).format('DD/MM/Y') === moment(idVerificationData.date_of_birth).format('DD/MM/Y')?'gray-color-500':"color-black  info-not-match"}`}>
                                                            {existingData.date_of_birth &&
                                                            <>
                                                                {moment(existingData.date_of_birth).format('DD/MM/Y')}

                                                            </>
                                                            }

                                                        </Text>
                                                    </td>
                                                    <td>
                                                        <div className="doc_icon_info">
                                                            <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${moment(existingData.date_of_birth).format('DD/MM/Y') === moment(idVerificationData.date_of_birth).format('DD/MM/Y')?'gray-color-500':"color-black"}`}>

                                                                {idVerificationData.date_of_birth &&
                                                                <>
                                                                    {moment(idVerificationData.date_of_birth).format('DD/MM/Y')}
                                                                </>
                                                                }
                                                            </Text>
                                                            {moment(existingData.date_of_birth).format('DD/MM/Y') === moment(idVerificationData.date_of_birth).format('DD/MM/Y') ?
                                                                <Icon value={"verified"} colorClass={'success-200-svg'}/>
                                                                :
                                                                <Icon value={"error"} colorClass={'danger-200-svg'}/>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {idVerificationData.nationality &&
                                                <tr>
                                                    <td>
                                                        <Text type="body-text-2" fontWeight={"medium-font"}
                                                              cssClasses={"gray-color"}>
                                                            Nationality
                                                        </Text>
                                                    </td>
                                                    <td>
                                                        <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${existingData.nationality === idVerificationData.nationality?'gray-color-500':"color-black  info-not-match"}`}>
                                                            {existingData.nationality}
                                                        </Text>
                                                    </td>
                                                    <td>
                                                        <div className="doc_icon_info">
                                                            <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${existingData.nationality === idVerificationData.nationality?'gray-color-500':"color-black"}`}>
                                                                {idVerificationData.nationality}
                                                            </Text>
                                                            {existingData.nationality === idVerificationData.nationality ?
                                                                <Icon value={"verified"} colorClass={'success-200-svg'}/> : <Icon value={"error"} colorClass={'danger-200-svg'}/>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {idVerificationData.gender &&
                                                <tr>
                                                    <td>
                                                        <Text type="body-text-2" fontWeight={"medium-font"}
                                                              cssClasses={"gray-color"}>
                                                            Gender
                                                        </Text>
                                                    </td>
                                                    <td>
                                                        <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${existingData.gender === idVerificationData.gender?'gray-color-500':"color-black  info-not-match"}`}>
                                                            {existingData.gender}
                                                        </Text>
                                                    </td>
                                                    <td>
                                                        <div className="doc_icon_info">
                                                            <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={`${existingData.gender === idVerificationData.gender?'gray-color-500':"color-black"}`}>
                                                                {idVerificationData.gender}
                                                            </Text>
                                                            {existingData.gender === idVerificationData.gender ?
                                                                <Icon value={"verified"} colorClass={'success-200-svg'}/> : <Icon value={"error"} colorClass={'danger-200-svg'}/>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                </table>
                            </div>
                         </div>

                         <div className="doc_modal_info_bottom mt-40 mb-40 text-center">
                            <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"gray-color"}>
                            By continuing, we will update your profile information with the details provided in your ID document. If you decide to upload a new document your previous document will be deleted.
                            </Text>
                         </div>

                        <div className="confirm-buttons-wrap document-match-modal-buttons-wrap">
                            <Button type="secondary" size="large" onClick={()=>getOnfidoParamsApi()} iconLeft={<Icon value="upload"/>} label={confirm_btn_title ? confirm_btn_title : "Try another document"}/>
                            <Button type="primary"
                                    microLoading={formLoading}
                                    size="large" iconLeft={<Icon colorClass={"gray-50-svg"} size={"medium"} value="refresh"/>} label={cancel_btn_title ? cancel_btn_title : 'Confirm updates'}
                                    onClick={() => {
                                        updateOnfidoProfileData();
                                        //closeModal();
                                    }}
                            />
                        </div>

                    </div>
                 }
            </Modal.Body>
        </Modal>
    );
}