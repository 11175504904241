//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState, useEffect, useRef} from "react";
import {Text} from "../../themes/userq/Text/Text";
import {Button} from "../../themes/userq/Button/Button";
import {roundNumber} from "../../../lib/helpers";


export default function ExistingTesterConcludeTestModal({openExistingTesterConcludeTestModal,closeExistingTesterConcludeTestModal, test,isAssignsCredits,cssClass}) {
    //console.log(test);
    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeExistingTesterConcludeTestModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    return (
        <Modal
            show={openExistingTesterConcludeTestModal}
            centered
            className={`fade custom-modal-wrap new-tester-given-test congratulation-modal-wrap ${cssClass}`}
        >
            <Modal.Body className={test.language=="ar"? 'arabic_wrapper':''} ref={wrapperRef}>
                <img className="congrts-img"
                     src={process.env.REACT_APP_URL + "img/congrts.svg"}
                     alt="cross"
                />

                <div className="congrats-modal-text">

                    {/*<Text type={"h1"}>*/}
                    {/*    {test.language=="en"?'Congratulation':'تهنئة'}*/}
                    {/*</Text>*/}

                    {!isAssignsCredits?
                        <div>
                            <Text type={"h1"}>
                                {test.language=="en"?'Congratulations':'تهانينا'}
                            </Text>

                            <Text type={"h2"}>
                            {test.language === 'en'?
                                "You've successfully completed the test"
                                :<span className="w-100">لقد أكملت الاختبار بنجاح</span>}
                            </Text>

                            <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                {test.language === 'en' ?
                                    "Your response will be reviewed, and if it violates our guidelines, your score may be lowered, and we'll let you know by email."
                                    :<span className="w-100">ستتم مراجعة ردودك، وإذا كان هناك أي انتهاك لإرشاداتنا، فقد يتم خفض درجاتك. سيتم إبلاغك عن طريق البريد الإلكتروني.</span>
                                }
                            </Text>

                        </div>
                        :
                        <div>
                            <Text type={"h1"}>
                                {test.language=="en"?'Congratulations':'تهانينا'}
                            </Text>

                            <Text type={"h2"}>
                            {test.language === 'en'?
                                `$${test.recruitment_fees_for_tester.toFixed(2)} has been credited to your wallet`
                                :<span className="w-100 "> تمت إضافة&nbsp;{test.recruitment_fees_for_tester.toFixed(2)}$&nbsp;إلى محفظتك</span>}
                            </Text>

                            <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                {test.language === 'en' ?
                                    "Your response will be reviewed, and if it violates our guidelines, your score may be lowered, and we'll let you know by email."
                                    :<span className="w-100 ">ستتم مراجعة ردودك، وإذا كان هناك أي انتهاك لإرشاداتنا، فقد يتم خفض درجاتك. سيتم إبلاغك عن طريق البريد الإلكتروني.</span>
                                }
                            </Text>
                        </div>
                    }

                    <div className="congrts-modal-wrap">
                        <Button
                            type={"primary"}
                            size={"large"}
                            label={test.language === 'en'?"Your dashboard":"لوحة القيادة الخاصة بك"}
                            onClick={() => {
                                //closeExistingTesterConcludeTestModal(false);
                                navigate('/dashboard');
                            }}
                        />
                    </div>

                </div>
            </Modal.Body>

            {/*<Modal.Body className="modal-lg p-0">*/}
            {/*    <div className={`modal-inner-text p-64 text-left ${test.language=="ar"? 'arabic_wrapper':''}` }>*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className="close"*/}
            {/*            onClick={() => {*/}
            {/*                closeExistingTesterConcludeTestModal(false);*/}
            {/*            }}*/}
            {/*            aria-label="Close"*/}
            {/*        >*/}
            {/*            <span aria-hidden="true">*/}
            {/*            <img*/}
            {/*                src={process.env.REACT_APP_URL + "images/cross.svg"}*/}
            {/*                alt="cross"*/}
            {/*            />*/}
            {/*            </span>*/}
            {/*        </button>*/}

            {/*        <div>*/}
            {/*    {test.target_group.target_type=="test-links"?*/}
            {/*    <div>*/}
            {/*    <h2>{test.language=="en"?"Thanks for your feedback":"شكرا لملاحظاتك"}</h2>*/}
            {/*    */}
            {/*    {test.language === 'en'?*/}
            {/*    <p className="w-100 text-left">$0 has been credited to your wallet.</p>*/}
            {/*    :<p className="w-100 text-right">$0 تمت إضافته إلى محفظتك. &nbsp;</p>}*/}
            {/*    */}
            {/*    </div>*/}
            {/*    :*/}
            {/*    <div>*/}
            {/*        <h2>{test.language=="en"?"Thanks for your feedback":"شكرا لملاحظاتك"}</h2>*/}
            {/*        */}
            {/*        {test.language === 'en'?*/}
            {/*        <p className="w-100 text-left">Congrats! ${test.recruitment_fees_for_tester.toFixed(2)} has been credited to your wallet.</p>*/}
            {/*        :<p className="w-100 text-right"> تهاني!  ${test.recruitment_fees_for_tester.toFixed(2)} تمت إضافته إلى محفظتك. &nbsp;</p>}*/}
            {/*        */}
            {/*    </div>*/}
            {/*    }*/}

            {/*</div>*/}

            {/*        <div className="button-wrap d-flex justify-content-between new-tester-conclude mt-32">*/}
            {/*            <div  className="button primary-btn w-100 m-0 ok-button-conclusion" onClick={() => {*/}
            {/*                closeExistingTesterConcludeTestModal(false);*/}
            {/*            }}>{test.language === 'en'?"OK":"موافق"}</div>*/}
            {/*        </div>*/}


            {/*    </div>*/}
            {/*</Modal.Body>*/}
        </Modal>
    );
}