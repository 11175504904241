import {useRef} from "react";
import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import BarChart from "./bar_chart_v2";
import LinearAxisChart from "./linear_axis_chart_v2";
import exportAsImage from "../../../../lib/exportAsImage";

export const  LinearAxisChartStatsBox = ({title, subtitle, percentage, size}) =>{

    const exportPngRef = useRef();

    return<div className="chart-hold-wrap position-relative linear-axis-chart">
        <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title)}>
            <Icon value={'download'} size={'small'} hover={'true'}></Icon>
            <div className="tooltip-wrapper top-tooltip">
                <p class="body-text body-text-2 normal-font ">Download</p>
            </div>
        </div>
        <div className="donwload-chart-ref" ref={exportPngRef}>
            <Box  size={size ? size : "large"}>
                <div className="linear-axis-chart">
                    <Text type={"h4"} fontWeight={"medium-font"}>{title}</Text>
                    {subtitle}
                    <LinearAxisChart id={"linear-axis-chart"} percentage={percentage} width={100} height={120} />
                </div>

            </Box></div></div> ;
}