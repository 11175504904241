import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout";
import { useNavigate,useParams,Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getTestExportData } from "../../services/test";
import {LayoutResearcher} from "../themes/userq/Layouts/layout_researcher";
import {Text} from "../themes/userq/Text/Text";
import {Icon} from "../themes/userq/Icon/Icon";
import {Input} from "../themes/userq/Input/Input";
import {Button} from "../themes/userq/Button/Button";
import {LoadingIcon} from "../themes/userq/Loader/loadingIcon";
import { Logo } from '../themes/userq/Logo/Logo';
import {showToast} from "../../lib/helpers";
import {Footer} from "../themes/userq/Footer/Footer";

export default function EarnAndInvite() {
    const navigate = useNavigate();
    let { token } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloadLink, setDownloadLink] = useState({type:''});

    const getData = () => {
        let usertoken = ReactSession.get("token");

        setIsLoading(true);

        getTestExportData({ id: token },usertoken).then((response) => {
            setIsLoading(false);
            if (response.success) {


               // window.open(response.url, '_blank', 'noreferrer');


                var newWin = window.open(response.url);
                if(!newWin || newWin.closed || typeof newWin.closed=='undefined')
                {
                    //POPUP BLOCKED
                    console.log('popup blocked')
                    setDownloadLink({type:'popup-blocked'});
                }else{
                    // let newTab = window.open();
                    // newTab.location.href = response.url;
                    // setTimeout (function() {newTab.close();},400);

                    console.log('popup not blocked')
                    setDownloadLink({type:'downloaded'});
                }
            }else{
                setDownloadLink({type:'expired'});
               // showToast(response.message, "error");
            }
        });
    };





    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - test result report";
        getData();

    }, []);

    return (
        <div className={"main-page-download-report"} >

            <div className="login-page-header">
                <div className="researcher-registerheader login-page-header">
                    <div className="container">
                        <div className='row align-items-center'>
                            <div className="col-xs-6 col-sm-6 login-hdr-left">
                                <Link to={'/'} className="logo">

                                    <Logo theme={"black"} background={"white"} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="download-report-wrapper page-min-height" style={{minHeight:'calc(100vh - 185px)'}}>
                {isLoading &&
                <div className={'page-loader  accountloading-box'}>
                    <LoadingIcon/>
                </div>
                }
                {!isLoading &&
                <div className="download-lsxs-file">

                    <div className="row align-items-center">
                        <div className="col-md-12 invite-earn-left">
                            {isDownloadLink.type === 'popup-blocked' &&
                            <div className={`no-participants-data no-questions-data`}>
                                <div className="no-page-data-wrap popup-blocked-wrap" style={{ marginTop: "80px" }}>
                                    <div className="no-data-icon">
                                        <Icon colorClass={"danger-red-200-svg"} value={"error"} size={"large"} />
                                    </div>



                                    <Text type={"subtitle-2"} fontWeight="medium-font">
                                        Pop-ups were blocked on this page.                                       
                                    </Text>

                                    <Text type={"body-text-2"} fontWeight="semi-bold-font">
                                        We are unable to download your report because the browser has blocked pop-ups. <br/> 
                                        Please <b>Allow pop-ups</b> to be able to download your report
                                    </Text>
                                    <Button 
                                        label={"Try again"}
                                        type={"secondary"}
                                        size={"large"}
                                        iconLeft={<Icon value={"refresh"} size={"medium"} /> }
                                        onClick={()=>{getData()}}
                                    />
                                </div>
                            </div>
                            }
                            {isDownloadLink.type === 'downloaded' &&
                            <div className={`no-participants-data no-questions-data`}>
                                <div className="no-page-data-wrap popup-blocked-wrap" style={{ marginTop: "80px" }}>
                                    <div className="no-data-icon">
                                        <Icon value={"Success"} colorClass={'success-green-200-svg'} size={"large"} />
                                    </div>



                                    <Text type={"subtitle-2"} fontWeight="medium-font">
                                        Your file has downloaded successfully.                                   
                                    </Text>
                                </div>
                            </div>
                            }
                            {isDownloadLink.type !== 'popup-blocked' && isDownloadLink.type !== 'downloaded' && 
                            <div className={`no-participants-data no-questions-data`}>
                                <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                    <div className="no-data-icon">
                                        <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                    </div>



                                    <Text type={"subtitle-1"} fontWeight="medium-font">
                                        {isDownloadLink.type === 'downloaded' &&
                                            <>Your file is downloaded successfully.</>
                                        }

                                        {isDownloadLink.type === 'expired' &&
                                        <>Your file is expired. Please download a new report!</>
                                        }

                                        {isDownloadLink.type === 'popup-blocked' &&
                                            <>Popup blocked</>
                                        }
                                    </Text>

                                </div>
                            </div>
                            }

                        </div>

                    </div>
                </div>
                }
            </div>
            <Footer />
        </div>
    );
}
