import React, { useState } from "react";
import { Icon } from './../Icon/Icon';
import { Text } from "../Text/Text";
import { CheckBox } from './../CheckBox/CheckBox';
import { RadioButton } from './../RadioButton/RadioButton';
import { Tag } from './../Tag/Tag';
import { OptionsReadMore } from "../OptionsReadMore/OptionsReadMore";
import { DropdownComponentDragAndDrop } from "../Dropdown/DropdownInsideDragandDrop";
import { ToggleButton } from './../ToggleButton/ToggleButton';
import { QuestionImage } from "../QuestionImage/QuestionImage";


export const Question = ({ index, question, onCopy, onEdit, onDelete, allQuestions, saveJumpTo, type, onToggleLogic, language, hideDuplicateAction, updatedLogicArray, duplicateProgress }) => {

    var dropdownOptions = [];

    if (question.is_logic == 1 && allQuestions != undefined) {
        for (let i = index + 2; i <= allQuestions.length; i++) {
            dropdownOptions.push({ label: i == index + 2 ? "Next question" : "Question " + i, value: i });
        }
        dropdownOptions.push({ label: "End survey", value: "End Survey" });
    }

    const [viewAll, setViewAll] = useState(false);



    return (
        <>
            <div className={`lightgraybox questionbox-s newsingle-question added-question-card-wrap ${(question.questionType == "singlechoice" && type == "survey") ? "singlesurvey-view" : ""}`}>
                <div className={`d-flex questionbox-top ${(question.is_optional == true) ? "optional-q-top" : ""} added-question-readtmode-top-wrap`}>
                    <div className="d-flex question-topleftbox">
                        {!hideDuplicateAction &&
                            <div className="questiondrag">
                                <Icon value="move-object" colorClass={'gray-900-svg'} size="medium" hover={true} />
                            </div>
                        }
                        {hideDuplicateAction &&
                            <div className="questiondrag">
                                &nbsp;
                            </div>
                        }
                        <div className="question-middlebox">
                            {question.questionType ==
                                "free" ? (
                                    <>
                                <Text type="subtitle-2" cssClasses={'none-for-mobile question-no-for-big-screen'} fontWeight="semi-bold-font">
                                    Question {index + 1} -
                                    Free text
                                </Text>

                                <Text type="subtitle-2" cssClasses={'none-for-desktop question-no-for-small-screen'} fontWeight="semi-bold-font">
                                Q {index + 1} -
                                Free text
                                </Text></>

                            ) : question.questionType ==
                                "singlechoice" ? (
                                    <>
                                <Text cssClasses={'none-for-mobile question-no-for-big-screen'} type="subtitle-2" fontWeight="semi-bold-font">
                                    Question {index + 1} -
                                    Single
                                    choice
                                </Text>
                                <Text type="subtitle-2" cssClasses={'none-for-desktop question-no-for-small-screen'} fontWeight="semi-bold-font">
                                Q {index + 1} -
                                Single choice
                                </Text>
                                </>
                            ) : question.questionType ==
                                "multiplechoice" ? (
                                 <>
                                <Text type="subtitle-2" cssClasses={'none-for-mobile question-no-for-big-screen'} fontWeight="semi-bold-font">
                                    Question {index + 1} -
                                    Multi-choice
                                </Text>
                                <Text type="subtitle-2" cssClasses={'none-for-desktop question-no-for-small-screen'} fontWeight="semi-bold-font">
                                Q {index + 1} -
                                Multi-choice
                                </Text>
                                </>
                            ) : question.questionType ==
                                "ratingscale" ? (
                                <> 
                                <Text type="subtitle-2" cssClasses={'none-for-mobile question-no-for-big-screen'} fontWeight="semi-bold-font">
                                    Question {index + 1} -
                                    Rating scale
                                </Text>
                                <Text type="subtitle-2" cssClasses={'none-for-desktop question-no-for-small-screen'} fontWeight="semi-bold-font">
                                Q {index + 1} -
                                Rating scale
                                </Text>
                                </>
                            ) : question.questionType ==
                                "likertscale" ? (
                                 <>
                                <Text type="subtitle-2" cssClasses={'none-for-mobile question-no-for-big-screen'} fontWeight="semi-bold-font">
                                    Question {index + 1} -
                                    Likert scale
                                </Text>
                                <Text type="subtitle-2" cssClasses={'none-for-desktop question-no-for-small-screen'} fontWeight="semi-bold-font">
                                Q {index + 1} -
                                Likert scale
                                </Text>
                                </>
                            ) : (
                                <>
                                <Text type="subtitle-2" cssClasses={'none-for-mobile question-no-for-big-screen'} fontWeight="semi-bold-font">
                                    Question {index + 1} -
                                    Ranking
                                    scale
                                </Text>
                                <Text type="subtitle-2" cssClasses={'none-for-desktop question-no-for-small-screen'} fontWeight="semi-bold-font">
                                Q {index + 1} -
                                Ranking scale
                                </Text>
                                </>
                                )}
                            {question.is_optional ==
                                true && (
                                    <span
                                        className="viewpart-optional">
                                        (Optional)
                                    </span>
                                )}
                        </div>
                    </div>

                    <div className="d-flex question-topbuttonbox">
                        {type == "survey" && (
                            <div className="questionaddlogic-v custom-control mb-0 none-for-mobile survey-login-toggle-for-big-screen">
                                <ToggleButton
                                    id={"logicCheck" + index}
                                    value={index}
                                    isChecked={Boolean(question.is_logic)}
                                    label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2" > Add logic </Text>}
                                    onChange={(e) => {
                                        onToggleLogic(e.target.checked ? 1 : 0, index);
                                    }}

                                />
                            </div>
                        )}
                        {!hideDuplicateAction &&
                            <div className={`question-readmode-icon ${duplicateProgress?'disabled-click':''} `}>
                                <span onClick={() => {
                                    if (onCopy) {
                                        onCopy(index)
                                    }
                                }
                                }>
                                    <Icon value="duplicate" colorClass={'gray-900-svg'} size="medium" hover={true} />
                                </span>
                            </div>
                        }
                        <div className="question-readmode-icon">
                            <span onClick={() => {
                                if (onEdit) {
                                    onEdit(index)
                                }
                            }
                            }>
                                <Icon value="edit" colorClass={'gray-900-svg'} size="medium" hover={true} />
                            </span>
                        </div>
                        <div className="ans-close-row question-readmode-icon">
                            <span onClick={() => {
                                if (onDelete) {
                                    onDelete(index)
                                }
                            }
                            }>
                                <Icon value="delete" colorClass={'gray-900-svg'} size="medium" hover={true} />
                            </span>
                        </div>
                    </div>
                </div>

                {type == "survey" && (
                            <div className="questionaddlogic-v custom-control none-for-desktop survey-logic-toggle">
                                <ToggleButton
                                    id={"logicCheck" + index}
                                    value={index}
                                    isChecked={Boolean(question.is_logic)}
                                    label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2" > Add logic </Text>}
                                    onChange={(e) => {
                                        onToggleLogic(e.target.checked ? 1 : 0, index);
                                    }}

                                />
                            </div>
                        )}

                <div className={`questiondescription-s d-flex question-added-view-wrap ${language == "ar" ? "arabic_wrapper" : ""}`}>
                    <div className="added-question-left d-flex">
                        {question.image_url &&
                        <QuestionImage url={question.image_url} editable={false} />
                        }
                        <p className="survey-question-added-info">
                            
                            {question.question}
                        </p>
                    </div>
                    {question.questionType !== "singlechoice" && question.is_logic == 1 && type == "survey" &&
                        <div className="logic-dropdown-readmode">
                            <DropdownComponentDragAndDrop
                                id={"logic-dropdown-" + index + "question"}
                                label="Jump to"
                                options={dropdownOptions}
                                value={question.jump_to_question != "End Survey" ? (question.jump_to_question == index + 2 ? "Next question" : "Question " + question.jump_to_question) : "End survey"}
                                onChange={(item) => {
                                    saveJumpTo(index, item.value);
                                }}
                                open={false}
                                logicUpdated={question.logicupdated && question.logicupdated == true ? true : false}
                                error={question.jump_to_question < index + 2 ? "Not applicable" : (question.error && question.error == 1 ? question.errorvalue : null)}
                            />

                        </div>}
                </div>


                {(question.questionType == "singlechoice") && question.options.map(
                    (option, i) => (
                        <>
                            {viewAll ?
                                <div className={`question-optionbox singlechoice-boxes ${(question.is_logic == 1) ? 'logicq-view' : ''}`} key={index + "" + option + "-" + i}>
                                    <div className="questionreadmode-left">
                                    
                                        <RadioButton isChecked={false} onChange={() => { }} />

                                        <div className={`option-value question-option-value-with-image ${language == "ar" ? "arabic_wrapper" : ""}`}>
                                            
                                            {question.optionImages && question.optionImages[i]  &&  question.optionImages[i].image_url &&
                                            <QuestionImage url={question.optionImages[i].image_url} editable={false} />
                                            } 
                                            {option}
                                        </div>

                                    </div>
                                    {question.is_logic == 1 &&
                                        <div className="logic-dropdown-readmode">

                                            <DropdownComponentDragAndDrop
                                                id={"logic-dropdown-" + index + "-" + i}
                                                label="Jump to"
                                                options={dropdownOptions}
                                                value={question.jumpTo[i] != "End Survey" ? (question.jumpTo[i] == index + 2 ? "Next question" : "Question " + question.jumpTo[i]) : "End survey"}
                                                onChange={(item) => {
                                                    saveJumpTo(i, item.value);
                                                }}
                                                logicUpdated={question.logicupdated && question.logicupdated[i] == true ? true : false}
                                                error={question.jumpTo[i] < index + 2 ? "Not applicable" : ((question.error && question.error[i] == 1) ? (question.errorValue && question.errorValue[i]) : null)}
                                            />

                                        </div>}
                                </div>
                                :
                                <>
                                    {i < 3 &&
                                        <div className={`question-optionbox singlechoice-boxes ${(question.is_logic == 1) ? 'logicq-view' : ''}`} key={index + "" + option + "-" + i}>
                                            <div className="questionreadmode-left">

                                                <RadioButton isChecked={false} onChange={() => { }} />

                                                <div className={`option-value question-option-value-with-image ${language == "ar" ? "arabic_wrapper" : ""}`}>
                                            {question.optionImages && question.optionImages[i] && question.optionImages[i].image_url &&
                                            <QuestionImage url={question.optionImages[i].image_url} editable={false} />
                                            } 
                                                    {option}
                                                </div>

                                            </div>

                                            {question.is_logic == 1 &&
                                                <div className="logic-dropdown-readmode">
                                                    <DropdownComponentDragAndDrop
                                                        id={"logic-dropdown-" + index + "-" + i}
                                                        label="Jump to"
                                                        options={dropdownOptions}
                                                        value={question.jumpTo[i] != "End Survey" ? (question.jumpTo[i] == index + 2 ? "Next question" : "Question " + question.jumpTo[i]) : "End survey"}
                                                        onChange={(item) => {
                                                            saveJumpTo(i, item.value);
                                                        }}
                                                        logicUpdated={question.logicupdated && question.logicupdated[i] == true ? true : false}
                                                        error={question.jumpTo[i] < index + 2 ? "Not applicable" : ((question.error && question.error[i] == 1) ? (question.errorValue && question.errorValue[i]) : null)}
                                                    />

                                                </div>}
                                        </div>
                                    }
                                </>
                            }
                        </>
                    )
                )}

                {(question.questionType === "singlechoice") && !viewAll && question.options && question.options.length > 3 &&
                    <div className="question-optionbox multiplechoice-boxes">
                        <div className="addadas" onClick={() => { setViewAll(true) }}>
                            +{question.options.length - 3} more
                        </div>
                    </div>
                }

                {(question.questionType == "multiplechoice") && question.options.map(
                    (option, i) => (
                        <>
                            {viewAll ?
                                <div className="question-optionbox multiplechoice-boxes"
                                    key={index + "" + option + "-" + i}>
                                        <div className="questionreadmode-left multiquestion-read-more">

                                            <CheckBox checked={false} onChange={() => {
                                            }} />

                                            <div className={`option-value question-option-value-with-image ${language == "ar" ? "arabic_wrapper" : ""}`}>
                                                {question.optionImages && question.optionImages[i]  &&  question.optionImages[i].image_url &&
                                                <QuestionImage url={question.optionImages[i].image_url} editable={false} />
                                                } 
                                                {option}
                                            </div>
                                        </div>
                                </div>
                                :
                                <>
                                    {i < 3 &&
                                        <div className="question-optionbox multiplechoice-boxes"
                                            key={index + "" + option + "-" + i}>
                                            <div className="questionreadmode-left multiquestion-read-more">
                                                <CheckBox checked={false} onChange={() => {
                                                }} />

                                                <div className={`option-value question-option-value-with-image ${language == "ar" ? "arabic_wrapper" : ""}`}>
                                                    {question.optionImages && question.optionImages[i]  &&  question.optionImages[i].image_url &&
                                                    <QuestionImage url={question.optionImages[i].image_url} editable={false} />
                                                    } 
                                                    {option}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    )
                )}


                {(question.questionType === "multiplechoice") && !viewAll && question.options && question.options.length > 3 &&
                    <div className="question-optionbox multiplechoice-boxes">
                        <div className="addadas" onClick={() => { setViewAll(true) }}>
                            +{question.options.length - 3} more
                        </div>
                    </div>
                }

                {/* Akshay: Rating scale read mode */}

                {(question.questionType == "ratingscale") && <div className="scalquestion-outer">
                    <div className="scale-lnthbox">
                        Scale length: <span>{false && question.scale_length} </span>
                    </div>
                    {question.labels && !(question.labels[0] == "" && question.labels[1] == "" && question.labels[2] == "") &&
                        <div className={` question-tags ${language == "ar" ? "arabic_wrapper" : ""}`}
                        >
                            {question.labels.map(
                                (label, i) => (
                                    <>
                                        {label != "" &&
                                            <div className="rating-scale-labels" key={index + "" + label + "-" + i}>

                                                <Text type={"caption"} fontWeight="medium-font" cssClasses="rating-label-info">
                                                    {i === 0 && "Left:"}
                                                    {i === 1 && "Middle:"}
                                                    {i === 2 && "Right:"}
                                                </Text>
                                                <Tag title={label} type="purple-tag" />

                                            </div>
                                        }
                                    </>
                                )
                            )}
                        </div>
                    }

                    <div className={`scale-typebox ${question.labels &&  (question.labels[0] == "" && question.labels[1] == "" && question.labels[2] == "") ? "tag-labels-notavailable" : ""}`}>
                        Scale type: <span>{question.scale_type}</span>

                    </div>
                </div>}
                {(question.questionType == "likertscale") && <div className="scalquestion-outer">
                    <div className={` question-tags ${language == "ar" ? "arabic_wrapper" : ""}`}>
                        {question.options.map(
                            (option, i) => (
                                <div className="" key={index + "" + option + "-" + i}>
                                    <Tag title={option} type="purple-tag" />

                                </div>
                            )
                        )}
                    </div>
                    <div className={` question-scalebox-input ${language == "ar" ? "arabic_wrapper" : ""}`}>
                        <OptionsReadMore options={question.subQuestions} />
                    </div>
                </div>}
                {(question.questionType == "rankingscale") && <div className={` padd-lr-25 question-scalebox-input ${language == "ar" ? "arabic_wrapper" : ""}`}>
                    <OptionsReadMore options={question.options} />
                </div>
                }
            </div>
        </>
    )
}

Question.propTypes = {
    // question: PropTypes.string,
    // questionType: PropTypes.string,

};

Question.defaultProps = {
    // question: "",
    // questionType:"freetext",

};