import React, { useEffect, useState, useRef,useImperativeHandle } from "react";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import { QuestionCard } from "../../themes/userq/QuestionCard/QuestionCard";
import { Text } from '../../themes/userq/Text/Text';
// import Question from './../../tests/question';
import Questions from "./introduction/questions";
import { AddQuestion } from "../../themes/userq/AddQuestion/AddQuestion";
import { Question } from "../../themes/userq/Question/Question";
import EditQuestion from "./introduction/editQuestion";
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import toast from "react-hot-toast";
import { QuestionInput } from '../../themes/userq/Question/QuestionInput';
import { duplicateQuestion, saveConclusionQuestions } from './../../../services/test';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import {EditQuestionInput} from "../../themes/userq/Question/EditQuestionInput.js";
import { settings } from "../../../data/settings.js";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

export const ConclusionQuestions = React.forwardRef(({ questions, test, setLoading, changeCount, updateQuestions },ref) => {

    // check question validate error ref
    const questionInputValidateRef = useRef(null);

    const { getTestLength } = useTestBuilderData();

    const [questionType, setQuestionType] = useState("");
    // const [briefingQuestions, setBriefingQuestions] = useState([]);

    const [addingBriefingQuestions, setAddingBriefingQuestions] = useState(false);
    const [edittingBriefingQuestions, setEdittingBriefingQuestions] =
        useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [language, setLanguage] = useState(test.language);
    const lang = useRef("en");
    const [dataSaving, setDataSaving]=useState(false);
    const [dataSavingEnabled, setDataSavingEnabled]=useState(false);

    const [duplicateProgress, setDuplicateProgress] = useState(false);

    let duplicateController = useRef();
    

    let conclusionController = useRef();

    let questionarray = [];
    questions.forEach((question) => {
        questionarray.push(JSON.parse(question));
    });
    const [briefingQuestions, setBriefingQuestions] = useState(questionarray);

    const [tempAddQuestion, setTempAddQuestion] = useState({
        question: "",
        is_optional: false,
        is_logic: false,
        jump_to: "End Survey",
        options:[]
    });

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={()=>{toast.dismiss();}} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const addquestion = (type) => {
        if (briefingQuestions.length<5) {
            setQuestionType(type);
        } else {
            showError("You've hit the maximum amount of questions! If you need to add more, consider removing earlier ones.");
        }
    };

    const removequestion = (index) => {
        const list = [...briefingQuestions];
        list.splice(index, 1);
        setBriefingQuestions(list);
        // if (!edittingBriefingQuestions) {
        //     const list = [...briefingQuestions];
        //     list.splice(index, 1);
        //     setBriefingQuestions(list);
        // } else {
        //     showError("Finish editing question");
        // }
    };
    const cancelQuestionHandler = () => {
        setAddingBriefingQuestions(false);
        setQuestionType("");
        setEdittingBriefingQuestions(false);
    };
    const cancelEditQuestionHandler = () => {
        setEdittingBriefingQuestions(false);
        setEditQuestionId(null);
    };

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    // checking function only
    useImperativeHandle(ref, () => ({
        questionValidateForm: questionValidateForm,
        resetErrors: resetErrors
    }),[tempAddQuestion]);

    const questionValidateForm = () => {
        setDataSavingEnabled(true);
        if(edittingBriefingQuestions && questionInputValidateRef){
            var checkIfErrorExits = questionInputValidateRef.current && questionInputValidateRef.current.handleQuestionSubmit(true)
            if(checkIfErrorExits){

                return false;
            }
        }
        return true;
    }
    const resetErrors = () => {
        if(edittingBriefingQuestions && questionInputValidateRef){
            questionInputValidateRef.current && questionInputValidateRef.current.resetErrors()
        }
    }

    const duplicatequestion = (index) => {
        if (!edittingBriefingQuestions) {
            if(!duplicateProgress && briefingQuestions.length<5){
                let list = [...briefingQuestions];
                let question = Object.assign({},briefingQuestions[index]);
                
                if (duplicateController.current) {
                    duplicateController.current.abort();
        
                }
                
                const controller = new AbortController();
        
                duplicateController.current = controller;

                setDuplicateProgress(true);
                
                setLoading({ 'status': 'loading', 'message': '' });
                
                let token = ReactSession.get("token");

                var data = {};

                data.test_id = test.id;

                data.question = question;

                duplicateQuestion(data, token, duplicateController.current?.signal).then((response) => {

                    setDuplicateProgress(false);

                    if (response.success) {

                        setLoading({ 'status': 'success', 'message': '' });
                    
                        list.splice(index, 0, response.question);
                
                
                
                        setBriefingQuestions(list);

                        //scrolling to duplicated question

                        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight+document.querySelector('.three-column-layout-top-header').offsetHeight;
                        var offsetTop = Position(document.getElementById("conc-"+(index)));
                        console.log(offsetTop - fixedElementHeight);
                        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

                        setTimeout(function () {
                            var duplicateQuestionElement = document.getElementById("conclu-question-"+(index+1));

                            if(duplicateQuestionElement){

                                duplicateQuestionElement.classList.add("question-duplicate-animation");

                                setTimeout(function () {

                                    duplicateQuestionElement.classList.remove("question-duplicate-animation");
                                    // console.log(duplicateQuestionElement);
                                }, 5000)
                            }
                        }, 100)
                    
                    } else {
                        setLoading({ 'status': 'error', 'message': response.message });
                    }
                });
                
                

            }else{
                showError("You've hit the maximum amount of questions! If you need to add more, consider removing earlier ones.");
            }
        } else {
            showError("Finish editing question");
        }
    };
    const edittingQuestionsHandler = (index) => {
        if (!edittingBriefingQuestions) {
            setEdittingBriefingQuestions(true);
            setEditQuestionId(index);
        } else {
            showError("Finish editing earlier question");

        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
            border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
            border:'1px dashed'
        });
    };

    const handleDragEnd = result => {
        setPlaceholderProps({});
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            briefingQuestions,
            result.source.index,
            result.destination.index
        );

        setBriefingQuestions(items);
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const saveQuestions = (saveUsingNavigation) => {

        if (conclusionController.current) {
            conclusionController.current.abort();

        }
        const controller = new AbortController();

        conclusionController.current = controller;

        let token = ReactSession.get("token");
        var data = new FormData();

        data.append("test_id", test.id);


        briefingQuestions.forEach((item) => {
            data.append("debriefing_questions[]", JSON.stringify(item));
        });
        if(dataSaving==false){
            setLoading({'status':'loading','message':''});
            saveConclusionQuestions(data, token, conclusionController.current?.signal).then((response) => {
                if (response.success) {
                    setLoading({'status':'success','message':''});

                    getTestLength(test.id);
                    
                } else {
                    setLoading({'status':'error','message':response.message});
                }
            });
        }
    };

    useEffect(() => {
        if(dataSavingEnabled){
            changeCount(briefingQuestions.length);
            let questionarrayStringified = [];
            briefingQuestions.forEach((question) => {
                questionarrayStringified.push(JSON.stringify(question));
            });
            updateQuestions(questionarrayStringified);
            saveQuestions(true);
        }else{
            setDataSavingEnabled(true)
        }

    }, [briefingQuestions]);
    const saveConclusionQuestionsAPI = (questions) => {

        if (conclusionController.current) {
            conclusionController.current.abort();

        }
        const controller = new AbortController();

        conclusionController.current = controller;

        let token = ReactSession.get("token");
        var data = new FormData();

        data.append("test_id", test.id);


        questions.forEach((item) => {
            data.append("debriefing_questions[]", JSON.stringify(item));
        });
        if(dataSaving==false){
            setLoading({'status':'loading','message':''});
            saveConclusionQuestions(data, token, conclusionController.current?.signal).then((response) => {
                if (response.success) {
                    setLoading({'status':'success','message':''});
                } else {
                    setLoading({'status':'error','message':response.message});
                }
            });
        }
    };
    const updateConclusionQuestions = (questions)=>{
        saveConclusionQuestionsAPI(questions);
    }

    return (<>
        <Text type="body-text-2" fontWeight="medium">Ask your testers post-test questions to gain deeper insights. You can add a maximum of 5 questions.</Text>

        <div className={`ans-list-wrap question-prev-wrap mt-32 `}>
            {briefingQuestions.length === 0 ? (
                <div className="nodata-wrap"></div>
            ) : (
                <div className="added-question-wrap mb-32" style={{ position: 'relative' }}>
                    <DragDropContext
                        onDragEnd={handleDragEnd}
                        onDragStart={handleDragStart}
                        onDragUpdate={handleDragUpdate}
                    >
                        <Droppable droppableId="droppableconclusion">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {briefingQuestions.map((question, index) => (
                                        <div key={index}
                                             className="survey-added-question-repeat"
                                        >
                                            <Draggable
                                                draggableId={"draggableconclusion-" + index}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={
                                                            snapshot.isDragging
                                                                ? "question-answers-dragging draggable-element-start"
                                                                : ""
                                                        }
                                                    >
                                                        {editQuestionId === index ? (
                                                            <></>
                                                        ) : (
                                                            <div
                                                                key={index}
                                                                id={'conclu-question-'+ index}
                                                                className={`survey-question-preview ${snapshot.isDragging?'draggable-inner-element-start':'grey-bg'}`}
                                                            >
                                                                <div
                                                                    className={`question-answer-holder`}>
                                                                    <div {...provided.dragHandleProps}>
                                                                        <Question duplicateProgress={duplicateProgress} index={index} question={question} onCopy={()=>duplicatequestion(index)} onDelete={()=>removequestion(index) } onEdit={()=>edittingQuestionsHandler(index)} language={test.language} />
                                                                    </div>
                                                                </div>
                                                                <div id={"conc-"+index}></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                            <div className="question-edit">
                                                {edittingBriefingQuestions &&
                                                editQuestionId === index && (
                                                    <EditQuestionInput
                                                        questionSection={"questions"}
                                                        id_prefix="conclusion"
                                                        questionDetails={
                                                            briefingQuestions[index]
                                                        }
                                                        cancelEditQuestion={
                                                            cancelEditQuestionHandler
                                                        }
                                                        editQuestions={(question) => {
                                                            briefingQuestions.splice(
                                                                index,
                                                                1,
                                                                question
                                                            );
                                                            setBriefingQuestions([
                                                                ...briefingQuestions,
                                                            ]);
                                                            updateConclusionQuestions([...briefingQuestions]);
                                                        }}
                                                        language={language}
                                                        ref={questionInputValidateRef}
                                                        other={
                                                            language == "en" ?
                                                                (question.questionType == "free"
                                                                    ? "Other"
                                                                    : briefingQuestions[
                                                                        index
                                                                        ].options.slice(-1) == "Other"
                                                                        ? true
                                                                        : false )
                                                                :( question.questionType == "free"
                                                                ? "آخر"
                                                                : briefingQuestions[
                                                                    index
                                                                    ].options.slice(-1) == "آخر"
                                                                    ? true
                                                                    : false)
                                                        }
                                                        questionno={index + 1}
                                                        questionlist={briefingQuestions}
                                                        changeQuestionType={(questionType) => { briefingQuestions[index].questionType = questionType }}
                                                        addLikertSubquestions={() => { briefingQuestions[index].subQuestions = [""] }}
                                                        onDelete={()=>removequestion(index) }
                                                        onUpdate={(question)=>{
                                                            setTempAddQuestion(question);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {provided.placeholder}
                                    {!isEmpty(placeholderProps) && (
                                        <div
                                            className="placeholder"
                                            style={{
                                                top: placeholderProps.clientY,
                                                left: placeholderProps.clientX,
                                                height: placeholderProps.clientHeight,
                                                width: placeholderProps.clientWidth,
                                                position: "absolute",
                                                borderColor: "#000000",
                                                background:placeholderProps.background,
                                                borderRadius:'10px',
                                                border:placeholderProps.border,
                                                margin: '-15px 0px 10px'
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}

            {questionType === "" ? (
                <div className="add-more-question-option">
                    {briefingQuestions.length<5 && <>
                        {briefingQuestions.length>0 && <div className="add-more-question-heading"><Text type="body-text-2" fontWeight="medium">Add more question(s)</Text></div>}
                        <div className="stud-tiles-hold mt-10">
                            <QuestionCard
                                questiontype="Free Text"
                                onClick={() => {
                                    addquestion("free");
                                    setEdittingBriefingQuestions(true);
                                }} />
                            <QuestionCard
                                questiontype="Single choice"
                                onClick={() => {
                                    addquestion("singlechoice");
                                    setEdittingBriefingQuestions(true);
                                }} />
                            <QuestionCard
                                questiontype="Multiple-choice"
                                onClick={() => {
                                    addquestion("multiplechoice");
                                    setEdittingBriefingQuestions(true);
                                }} />
                            <QuestionCard
                                questiontype="Likert scale"
                                onClick={() => {
                                    addquestion("likertscale");
                                    setEdittingBriefingQuestions(true);
                                }} />
                            <QuestionCard
                                questiontype="Rating scale"
                                onClick={() => {
                                    setQuestionType("ratingscale");
                                    setEdittingBriefingQuestions(true);
                                }}
                            />
                            <QuestionCard
                                questiontype="Ranking scale"
                                onClick={() => {
                                    addquestion("rankingscale");
                                    setEdittingBriefingQuestions(true);
                                }} />
                        </div>
                    </>}
                    <div class="create-test-preview-area">
                        <Text
                            type={'overline'}
                            children={'Preview'}
                        />
                        <div className="create-test-preview-area-inner questionpreviewinner">
                            Choose a briefing question <br/> to preview
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${(language == "en") ? "" : "arabic_wrapper"}`}>
                    <QuestionInput
                        id_prefix="conclusion"
                        addQuestions={(question) => {
                            setBriefingQuestions([...briefingQuestions, question]);
                            setAddingBriefingQuestions(false);
                            setQuestionType("");
                            setEdittingBriefingQuestions(false);
                            updateConclusionQuestions([...briefingQuestions, question]);
                        }}
                        cancelQuestion={cancelQuestionHandler}
                        ref={questionInputValidateRef}
                        changeQuestionType={(questionType) => {
                            setQuestionType(questionType)
                        }}
                        language={language}
                        questionType={questionType}
                        questionlist={briefingQuestions}
                        questionno={briefingQuestions.length + 1}
                        onUpdate={(question)=>{
                            setTempAddQuestion(question);
                        }}
                    />

                </div>
            )}
        </div>
    </>)
});