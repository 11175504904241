import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { saveFirstclickTesterInterctions } from "../../../../services/test_result";
import { showToast } from "../../../../lib/helpers";
import { ArabicNumbers } from 'react-native-arabic-numbers';
import ExitTestModal from "../../dialog/exit_test_modal";
import { validateResultData } from "../../../../services/test_result";
import FirstClickTestTaskFlowQuestions from "./firstclick_test_task_flow_question";
import h337 from "heatmap.js"
import ConfirmYourClickModal from "./confirm_your_click_modal";
import { Preview } from "../../../themes/userq/Preview/preview";

export default function FirstClickTestTaskFlow({ test, task, active_task, result, timeSpent, moveToNextTask, isPaidUser }) {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let user = ReactSession.get("user");
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const overlayClass = useRef("prototype-overlay-class");
    const [loading, setLoading] = useState(false);
    const [clickModalOpen, setClickModalOpen] = useState(false);
    const [getStarted, setGetStarted] = useState(true);
    const [prototypeTask, setPrototypeTask] = useState(task);
    const travelledPath = useRef([]);
    const currentNode = useRef(null);
    const currentNodeclickMapData = useRef([]);
    const clickMapData = useRef([]);
    const [taskTimeSpent, setTaskTimeSpent] = useState(0);
    const nodeTimeSpent = useRef(0);
    const [questionScreen, setQuestionScreen] = useState(false);
    const [openExitTestModal, setOpenExitTestModal] = useState(false);
    const [previewModal, setPreviewModal] = useState({ open: false, file: "", file_type: "image" });

    // close exit test modal & rediect to home page if exits success
    const closeExitTestModal = (data) => {
        setOpenExitTestModal(false);
        if (data) {
            saveAbandonedTest(); // save abandoned test
            if (location.pathname.substr(1, 1) === 'p') {

                //navigate("/r/test/" + test.id + "/welcome");
                navigate("/");
            } else {
                if (!(ReactSession.get("token"))) {
                    navigate("/tester/sign-in");
                } else {
                    navigate("/");
                }

            }
        }
    };

    const saveAbandonedTest = () => {
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test && test.id);
        data.append("guest_token", ReactSession.get("guest_token"));
        data.append("validate", "abandoned");
        data.append("type", location.pathname.substr(1, 1));
        data.append("result_id", result && result.id);
        data.append("time_spent", timeSpent);

        setLoading(true);
        validateResultData(data, token).then((response) => {
            setLoading(false);

            if (response.success) {
                // console.log(window.history)
                navigate("/");
            } else {
                navigate("/");
            }
        });
    };

    useEffect(() => {
        setSidebarOpen(true);
        setGetStarted(true);
        setPrototypeTask(task);
        setLoading(false);
        travelledPath.current = [];
        currentNodeclickMapData.current = [];
        clickMapData.current = [];
        currentNode.current = null;

        setTaskTimeSpent(0);

    }, [active_task, task]);

    useEffect(() => {
        function tickTime() {
            setTaskTimeSpent((prevSeconds) => prevSeconds + 1);

            nodeTimeSpent.current = nodeTimeSpent.current + 1;

        }
        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    }, []);

    const saveQuestionData = (clickedcoordinates) => {
        setLoading(true);
        if (location.pathname.substr(1, 1) === "t") {
            let data = Object.assign({}, {});
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;
            data["task_time_spent"] = taskTimeSpent;
            data["task_id"] = task.id;
            data["click_map_data"] = clickedcoordinates;
            saveFirstclickTesterInterctions(data, ReactSession.get("token")).then(
                (response) => {
                    setLoading(false);
                    if (response.success) {
                        if (task.firstclick_test_task_question.length == 0) {
                            moveToNextTask(active_task + 1);
                        } else {
                            setQuestionScreen(true);
                        }

                    } else {
                        showToast(response.message, "error");
                    }
                }
            );
        } else {
            if (task.firstclick_test_task_question.length == 0) {
                moveToNextTask(active_task + 1);
            } else {
                setQuestionScreen(true);
            }
        }
    };

    const onImageClick = (e) => {
        var bounds = e.target.getBoundingClientRect();
        var natuaralimageheight = e.target.naturalHeight;
        var natuaralimagewidth = e.target.naturalWidth;
        var renderedimageheight = e.target.clientHeight;
        var renderedimagewidth = e.target.clientWidth;
        // console.log(bounds)
        // console.log(e);
        // console.log(e.clientX-bounds.left,e.clientY-bounds.top)

        currentNodeclickMapData.current = [{
            x: Math.round((e.clientX - bounds.left) * natuaralimagewidth / renderedimagewidth),
            y: Math.round((e.clientY - bounds.top) * natuaralimageheight / renderedimageheight),
            value: 1
        }];

        console.log(currentNodeclickMapData.current);
        console.log("currentNodeclickMapData");

        setClickModalOpen(true);

    }

    return (
        <>
            {!questionScreen ?

                <div className="tester-page-wrapper tester-welcome-page-wrap tester-test-intro" >

                    <div className={`prototype-test-wrap five-second-full-view-wrap prototype-test-fullscreen `}>
                        {loading && sidebarOpen ?
                            <div className="loader_section full-page-loader prototype-loader">
                                <LoadingIcon />
                            </div>
                            :
                            <>
                                {sidebarOpen &&
                                    <div className="figma_prototype_sidebar prototypesidebar">
                                        <div className="first-click-sidebar-inner">
                                        <div className="sidebar-header">
                                            {test.language == "ar"?
                                            <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text><Icon colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /></div>
                                            :
                                            <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Icon colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text></div>
                                            }
                                            <div className="contact-support-button">
                                                {user && user.role == "Tester" ?
                                                    <div onClick={() => { navigate('/t/support/contact?reason=Problem with the platform'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                    :
                                                    <>
                                                        {user && user.role == "Researcher" ?
                                                            <div onClick={() => { navigate('/r/support/contact'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                            :
                                                            <a href={'https://userq.com/contact-us/'} target={"_blank"} className="contact-support-guestuser">
                                                                <div onClick={() => { }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                            </a>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className="minimie-screen-icon" onClick={() => {
                                                setSidebarOpen(false);
                                                setGetStarted(false);
                                            }}>
                                                <Icon colorClass="gray-900-svg" value="Minimize cards" size={"small"} hover={true} />
                                            </div>
                                        </div>
                                        <div className="figma_prototype_sidebar_body">
                                            <div className="figma-prototype-sidebar-body-inner">
                                                {test.language == "ar" ?
                                                    <Text type="h3" >المهمة رقم {ArabicNumbers(active_task + 1)}</Text>
                                                    :
                                                    <Text type="h3" >Task {active_task + 1}</Text>
                                                }
                                                <Text type="body-text-2" fontWeight="medium-font">{task.description}</Text>
                                                {getStarted ?
                                                    <>
                                                        {test.language == "ar" ?
                                                            <Button
                                                                type={"primary"}
                                                                size="large"
                                                                onClick={() => {
                                                                    setSidebarOpen(false);
                                                                    setGetStarted(false);
                                                                    nodeTimeSpent.current = 0;
                                                                }}
                                                                label="لنبدأ"
                                                                iconRight={<Icon value={"backward-arrow-white"} size="medium" />}
                                                            />
                                                            :
                                                            <Button
                                                                type={"primary"}
                                                                size="large"
                                                                onClick={() => {
                                                                    setSidebarOpen(false);
                                                                    setGetStarted(false);
                                                                    nodeTimeSpent.current = 0;
                                                                }}
                                                                label="Get started"
                                                                iconRight={<Icon colorClass={"gray-50-svg"} value={"forward-arrow"} size="medium" />}
                                                            />
                                                        }
                                                    </>
                                                    :
                                                    <div className="free-flow-task-flow">
                                                        <Button
                                                        type={"primary"}
                                                        size="large"
                                                        onClick={() => {
                                                            setSidebarOpen(false);
                                                        }}

                                                        label={test.language == "ar" ? "عرض التصميم" : "View design"}
                                                        iconRight={<Icon colorClass={"gray-50-svg"} value={test.language == "ar" ?"back-arrow":"forward-arrow"} size="medium" />}
                                                    />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                }
                                <div className={`figma_prototype_design_screen_container ${!sidebarOpen ? "" : "prototype-overlay-class"}`} 
                                    style={{"filter":sidebarOpen?"blur(25px)":"blur(0px)", "-webkit-filter":sidebarOpen?"blur(25px)":"blur(0px)"}} >
                                    <div className="figma_prototype_design_screen">
                                        <div id="heatmapContainer" className="prototype-iframe-overlay"></div>
                                        <div id="prototype-iframe" style={{ "backgroundColor": task.color }}>
                                            <>
                                                {/* <iframe
                                                id="figma_prototype_iframe"
                                                height="450"
                                                width="800"
                                                src={process.env.REACT_APP_IMG_URL+task.design_file}
                                                allowfullscreen
                                            /> */}
                                                <div id={`first-click-design-${task.id}`} className="first-click-image">
                                                    <img src={process.env.REACT_APP_IMG_URL + task.design_file} alt="first click design" onClick={onImageClick} />
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                    {!sidebarOpen &&
                                        <div className="figma_prototype_footer">
                                            {test.language == "ar" ?
                                                <Button
                                                    type={"secondary"}
                                                    onClick={() => {
                                                        setSidebarOpen(true);
                                                    }}

                                                    label="اقرأ المهمة"
                                                    iconLeft={<Icon value={"eye"} size="medium" />}
                                                />
                                                :
                                                <Button
                                                    type={"secondary"}
                                                    onClick={() => {
                                                        setSidebarOpen(true);
                                                    }}

                                                    label="Read task"
                                                    iconLeft={<Icon value={"eye"} size="medium" />}
                                                />
                                            }
                                            <Text type="caption" >{test.language == "ar" ? 'راجع المهمة المعطاة وتنقل عبر التصميم' : 'Review the task given and navigate through the design.'} </Text>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        <ConfirmYourClickModal
                            test={test}
                            openModal={clickModalOpen}
                            closeModal={() => { setClickModalOpen(false) }}
                            confirm={() => {
                                saveQuestionData(currentNodeclickMapData.current);
                                setClickModalOpen(false);
                            }}
                            deny={() => {
                                setClickModalOpen(false);
                            }}
                        />
                        <ExitTestModal
                            openExitTestModal={openExitTestModal}
                            closeExitTestModal={closeExitTestModal}
                            test={test}
                            isPaidUser={isPaidUser}
                        />
                    </div>
                </div>
                :
                <div className="preference-test-followup-questions mt-40 first-click-followup-questions">
                    <div className="selected-design">
                        <div className="preference-test-designs-repeat">
                            <div className="added-design-preview-thum">
                                <div className="prefer-design-image-view-hold" onClick={() => {
                                    setPreviewModal({ ...previewModal, open: true, img: process.env.REACT_APP_IMG_URL + task.design_file, file_type: "image", background: task.color });
                                }}>
                                    <div className="test-design-name-and-option-wrap" style={{ position: "relative" }} >
                                        <Text type={"body-text-1"} fontWeight={"medium-font"} cssClasses={`h3 prefer-design-name ${(test.language == "ar") ? "" : ""}`}>
                                                {task.title}
                                            &nbsp;
                                        </Text>
                                        <div className="design-max-screen-icon" >
                                            <Icon colorClass="gray-900-svg" size={"small"} value={'maximize'} hover="true" />
                                        </div>
                                    </div>
                                    <div className="prefer-test-selected-design-view">
                                        <div className="prefer-test-thum-data-hold">
                                            <div className="added-design-img-thm" style={{ "backgroundColor": task.color }}>
                                                <img src={process.env.REACT_APP_IMG_URL + task.design_file} alt={task.title} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="test-design-name-and-option-wrap">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FirstClickTestTaskFlowQuestions
                        firstclick_test_task_question={task.firstclick_test_task_question}
                        test={test}
                        result={result}
                        questionType="Survey"
                        timeSpent={timeSpent}
                        moveToNextTask={() => {
                            moveToNextTask(active_task + 1);
                            setQuestionScreen(false);
                        }}
                        task={task}
                    />
                </div>
            }
            <Preview rtl={test && test.language == "ar" ? true : false} open={previewModal.open} withMinimizeIcon={true} background={previewModal.background} loading={false} file_type={previewModal.file_type} img={previewModal.img} close={() => {
                setPreviewModal({ ...previewModal, open: false });
            }} />
        </>
    );
}