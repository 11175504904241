import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import moment from 'moment';
import { Icon } from './../Icon/Icon';
import { Tooltip } from './../Tooltip/Tooltip';
import { EmptySearchPlaceholder } from "../EmptySearchPlaceholder/EmptySearchPlaceholder";
import { TestResultQuestionImage } from "../QuestionImage/TestResultQuestionImage";

export const AggregatedDataTable = ({ metaData, aggregrateArray, sortData, search, test, clearSearch }) => {

    const scrollContainerRef = useRef(null);
    
    const [isScrolled, setIsScrolled] = useState(false);

    // highlight text if matches
    const escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    );

    const Highlight = ({ children = '' }) => {
        const patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
        const parts = String(children).split(patt);

        if (search) {
            return parts.map((part, index) => (
                patt.test(part) ? <mark className={'search-highlight'} key={index}>{part}</mark> : part
            ));
        } else {
            return children;
        }
    };

    const calculateAggregatePercentage = (vote) => {

        var totalPercentage = 0;
        if(metaData.questionType === 'singlechoice') {

            totalPercentage = (vote) / (metaData.aggregate_total_voters) * 100;
        }else{

            totalPercentage = (metaData.totalResultCount)?(vote) / (metaData.totalResultCount) * 100:0;
        }

        if (!isNaN(totalPercentage)) {
            return parseFloat(totalPercentage.toFixed(2));
        }

        return 0;
    }
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    return (
        <>
            <div ref={scrollContainerRef} className={`max-height-table  ${isScrolled ? 'scrolled' : ''}  ${aggregrateArray && aggregrateArray.length>8 ? "responsive-table-scroll":""}`}>
                <table className="table aggregated-data-table">
                    <thead>
                        <tr>
                            <th>Answers</th>
                            <th className="">
                                <div onClick={() => sortData('vote')} className={`${(metaData.sort_vote) ? metaData.sort_vote : ''} col-sort`}>
                                    Responses <span>
                                        {/* {metaData.sort_vote ?
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-applied.svg"
                                        } alt="icon" width={20} />
                                        :
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-icon.svg"
                                        } alt="icon" width={20} />
                                    } */}
                                        {metaData.sort_vote == "asc" &&
                                            <Icon value={'sortup'} />
                                        }
                                        {metaData.sort_vote == "desc" &&
                                            <Icon value={'sortdown'} />
                                        }
                                        {!metaData.sort_vote &&
                                            <Icon value={'sort-icon'} />
                                        }

                                    </span>
                                </div>
                            </th>
                            <th className="">% on total</th>
                            <th className="">Participants from the panel</th>
                            <th className="unknown-participant-tableheader">
                                <div className="d-flex unknown-th-info align-items-center">
                                Unknown participants{" "}

                                <span className="position-relative">
                                    <Icon value={"information"} colorClass="gray-700-svg" size="small" />
                                    <Tooltip
                                        className={"tooltip-wrapper"}
                                        type={"bottom"}
                                        data="If you directly shared the link with others, you might see unknown users outside your target demographic group."
                                    />
                                </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {aggregrateArray && aggregrateArray.map((result, index) => {
                            return (
                                <>
                                <tr key={index}>
                                    <td className="agger-td-first-column">
                                        <div className={`lc-column ${result.option_image ? 'question-result-with-img d-flex align-items-center' : 'no-question-image-td'} ${test.language === "ar" ? "arabic-font" : ""}`}>
                                            {result.option_image 
                                            ?
                                            <TestResultQuestionImage url={result.option_image.image_path} type="option" />
                                            :
                                            <>
                                                {metaData.anyOptionHaveImage && 
                                                <div className="no-question-image">-</div>
                                                }
                                            </>  
                                            }
                                            <Highlight>{result.option}</Highlight>
                                        </div>
                                    </td>
                                    <td className=""><b>{result.vote}</b></td>
                                    <td className="">{calculateAggregatePercentage(result.vote)} %</td>
                                    <td className=""><b>{result.total_tester}</b></td>
                                    <td className=""><b>{result.total_guest}</b></td>
                                </tr>
                                </>
                            );

                        })}
                        {aggregrateArray && aggregrateArray.length == 0 && (
                            <tr>
                                <td colSpan={5} className="border-0">
                                    <div className="text-center">
                                        <EmptySearchPlaceholder clearSearch={clearSearch} cssClass="aggregated-placeholder" />
                                    </div>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </>
    )


}