import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import { encryptId } from "../../lib/helpers.js";
import { Text } from "../themes/userq/Text/Text";
import { Icon } from "../themes/userq/Icon/Icon";
import { Button } from "../themes/userq/Button/Button";

import { showToast } from "../../lib/helpers";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { Tooltip } from "../themes/userq/Tooltip/Tooltip";
import {getOnfidoParams, saveOnfidoResult} from "../../services/id_verification";
import {init} from "onfido-sdk-ui";
import DocumentNotMatch from "../themes/userq/Modal/DocumentNotMatch";
import {getProfile} from "../../services/user";
import {CountdownTimer} from "./CountdownTimer";
import toast from "react-hot-toast";
import HelpModal from "../themes/userq/Modal/HelpModal";
import {Box} from "../themes/userq/Box/Box";

var onfido = {};

export const VerifyId = React.forwardRef(({isProfileVerified,isProfileVerifiedAt, confirmHandler,isType},ref)=>{


    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [idVerifyLoading, setIdVerifyLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);



    const [documentNotMatch, setDocumentNotMatch] = useState({ open: false });

    const [onfidoModal, setOnfidoModal] = useState({ open: false });

    const [profileVerified, setProfileVerified] = useState(isProfileVerified);

    const [isCallbackDate, setCallbackDate] = useState('10:00:00');

    const [profileVerifiedAt, setProfileVerifiedAt] = useState(isProfileVerifiedAt);

    const [limitReachedModal, setLimitReachedModal] = useState({open:false,timer:''});

    let user = ReactSession.get("user");


    useEffect(()=>{

        if(isProfileVerified){
            setProfileVerified(isProfileVerified);
        }

        if(isProfileVerifiedAt){
            setProfileVerifiedAt(isProfileVerifiedAt);
        }


    },[isProfileVerified, isProfileVerifiedAt]);

    const getOnfidoParamsApi = () => {



        setIdVerifyLoading(true);



        const token = ReactSession.get("token");



        getOnfidoParams(token)
            .then(response => {
                setIdVerifyLoading(false);

                setOnfidoModal({...onfidoModal, open:false});
                if (response.success) {
                    onfido = init({
                        token: response.data.sdk_token,
                        useModal: true,
                        isModalOpen: true,
                        onModalRequestClose: function() {
                            //alert(1)
                            // Update options with the state of the modal
                            onfido.setOptions({isModalOpen: false})
                        },
                        onComplete: function (data) {
                            getOnfidoResultApi();

                        },
                        workflowRunId: response.data.workflow_run_id,
                    })

                } else {

                    showToast(response.message,'error');
                }
            })


    }
    const getOnfidoResultApi = () => {



        setFormLoading(true);

        const token = ReactSession.get("token");

        saveOnfidoResult(token)
            .then(response => {
                setFormLoading(false);

                if (response.success) {

                    onfido.setOptions({isModalOpen: false})

                    // console.log(response.data)
                    if(response.data.user) {


                            let user = ReactSession.get("user");

                            user.profile_verification_status = response.data.user.profile_verification_status;
                            user.first_name = response.data.user.first_name;
                            user.last_name = response.data.user.last_name;
                            user.profile_verified_at = (response.last_attempt)?response.last_attempt:'';
                            ReactSession.set("user", user);

                            if(response.data.user.profile_verification_status == 'verified'){

                                showToast('Your ID document has been validated and your identity is now verified','success');

                                confirmHandler();

                            }else if(response.data.user.profile_verification_status == "duplicate_identity") {

                                confirmHandler(true);
                               // navigate("/tester/profile/duplicate_identity");

                            } else if(response.data.user.profile_verification_status == "minor") {

                                // navigate("/tester/profile/under_age");
                                confirmHandler(true);

                            } else if(response.data.user.profile_verification_status =="expired_document") {

                                //navigate("/tester/profile/duplicate_identity");
                                // confirmHandler();
                                setProfileVerified(response.data.user.profile_verification_status);
                                setProfileVerifiedAt(response.last_attempt);
                                setLimitReachedModal({open:true,timer:response.last_attempt});

                            }else if(response.data.user.profile_verification_status =="invalid_document") {

                                //navigate("/tester/profile/duplicate_identity");
                                // confirmHandler();
                                setProfileVerified(response.data.user.profile_verification_status)
                                setProfileVerifiedAt(response.last_attempt);
                                setLimitReachedModal({open:true,timer:response.last_attempt});

                            }else if(response.data.user.profile_verification_status =="id_not_matched_profile") {

                                ReactSession.set("document_not_matched_model","true");
                                confirmHandler();
                            }else{

                                showToast(response.message,'error')
                            }



                    //navigate("/t/profile");


                    }

                } else {
                    showToast(response.message,'error');
                }
            })


    }


    useImperativeHandle(ref, (isProfileVerifieds,isProfileVerifiedAts) => ({

        handleToastMessage: handleToastMessage

    }));

    const handleToastMessage = (isProfileVerifieds,isProfileVerifiedAts)=>{


            showToast(<>
                ID verification is required for payout.{" "}
                <span
                    onClick={(e) => {
                        e.preventDefault();

                        // check if user profile is verified or not
                        if (isProfileVerifieds === 'id_not_matched_profile') {
                            setDocumentNotMatch({
                                open: true,

                            });

                        }else if(isProfileVerifieds === 'expired_document' || profileVerified === 'invalid_document'){

                            setLimitReachedModal({open:true,timer:isProfileVerifiedAts});
                        } else {

                            getOnfidoParamsApi()
                        }
                        //

                    }}
                    className="link-text text-white cursor-pointer">
                       Verify your identity
                   </span>
            </>,"error")

    }


    return (
       <>
           {isType === 'banner' &&
           <>
               {(profileVerified === 'expired_document' || profileVerified === 'invalid_document') ?
                   <ToastMessage icon={"id"} type={"info"} message={
                       <><p>
                           To ensure the security and privacy of our platform, we highly encourage you to verify your
                           ID. Try again in
                           {" "}<CountdownTimer timerData={profileVerifiedAt}/>
                       </p></>} closable={false}/>
                   :
                   <>
                       <ToastMessage icon={"id"} type={"info"} message={
                           <><p>
                               To access more tests and enable payouts, please{" "}
                               <span
                                   onClick={(e) => {
                                       e.preventDefault();

                                       // check if user profile is verified or not
                                       if (user.profile_verification_status == 'id_not_matched_profile') {
                                           setDocumentNotMatch({
                                               open: true,

                                           });

                                       } else {
                                           setOnfidoModal({
                                               open:true
                                           });
                                       }
                                       //

                                   }}
                                   className="link-text">
                       verify your ID
                               </span><span className={'none-for-mobile'}>.</span>{" "}Verification may take 3-5 mins. Please have your ID document ready.
                           </p></>} closable={false}/>
                       <div id="onfido-mount"></div>
                   </>
               }
           </>
           }

           {isType === 'button' &&
                <>
                    {(profileVerified === 'expired_document' || profileVerified === 'invalid_document') ?
                        <Button
                            size={"large"}
                            type="primary"
                            label="Verify your ID"
                            iconLeft={<Icon colorClass="gray-50-svg" value="id" />}
                            onClick={() => {

                                setLimitReachedModal({open:true,timer:profileVerifiedAt});
                            }}
                        />

                        :
                        <>
                            <Button
                                size={"large"}
                                type="primary"
                                label="Verify your ID"
                                iconLeft={<Icon colorClass="gray-50-svg" value="id" />}
                                microLoading={idVerifyLoading}
                                onClick={(e) => {
                                    e.preventDefault();

                                    // check if user profile is verified or not
                                    if (user.profile_verification_status == 'id_not_matched_profile') {
                                        setDocumentNotMatch({
                                            open: true,

                                        });

                                    } else {

                                         getOnfidoParamsApi()
                                    }
                                    //

                                }}
                            />
                        </>
                    }
               </>
           }



           <DocumentNotMatch
               confirm_title={"We need to update your profile to match your ID"}
               openModal={documentNotMatch.open}
               close={() => {
                   setDocumentNotMatch({ open: false });
               }}
               confirmHandler={confirmHandler}
           />



           <HelpModal
               cssClass={"onfido-detail-summary-modal"}
               open={onfidoModal.open}
               close={()=>{
                   setOnfidoModal({...onfidoModal, open:false});
               }}
               heading={'Verify your Identity'}
               disableCross={true}
               body={
                   <div className="onfido-detail-summary-modal-data">
                       <div className="header-data">

                           <div className={'doc-sec d-flex'}>

                                <Icon value={"id"} size="large" />

                                <Text  type={"body-text-2"} cssClasses={'gray-color'} fontWeight={"medium-font"}>Please have your ID document ready</Text>
                           </div>

                           <div className={'time-sec d-flex'}>

                               <Icon  value={"time"} size="large" />

                               <Text  type={"body-text-2"}  cssClasses={'gray-color'} fontWeight={"medium-font"}>Verification may take 3 - 5 mins</Text>
                           </div>
                       </div>

                       <div className="middle-data">

                           <Box cssClasses="signofinformational-wrap gray-box p-32 mb-20">
                               <Text type={"subtitle-2"}  cssClasses={'color-black'} fontWeight={"semi-bold-font"}>Why it’s important to verify my identity?</Text>

                               <div className='sign-identity-repeat mb-20 mt-32'>
                                   <div className='sign-identity-top-head d-flex align-items-center'>
                                       <Icon value="security" size="medium"/>
                                       <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"semi-bold-font"}>Security</Text>
                                   </div>
                                   <Text type={"body-text-3"} cssClasses="gray-color" fontWeight={"medium-font"}>Verifying your identity helps to prevent fraud and unauthorized access to your account.</Text>
                               </div>

                               <div className='sign-identity-repeat mb-20'>
                                   <div className='sign-identity-top-head d-flex align-items-center'>
                                       <Icon value="key" size="medium"/>
                                       <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"semi-bold-font"}>Access to tests</Text>
                                   </div>
                                   <Text type={"body-text-3"} cssClasses="gray-color" fontWeight={"medium-font"}>Some tests might be available only for verified users. Get access to all tests by verifying your identity.</Text>
                               </div>

                               <div className='sign-identity-repeat'>
                                   <div className='sign-identity-top-head d-flex align-items-center'>
                                       <Icon value="black_dollar" size="medium"/>
                                       <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"semi-bold-font"}>Payouts</Text>
                                   </div>
                                   <Text type={"body-text-3"} cssClasses="gray-color" fontWeight={"medium-font"}>Only verified accounts are eligible to request a payout. If you have not yet verified your identity, please do so before requesting a payout.</Text>
                               </div>


                           </Box>

                       </div>


                       <div className="d-flex justify-content-center duplicate-test-modal-action">
                           <Button
                               size={"large"}
                               type="primary"
                               iconLeft={<Icon colorClass="gray-50-svg" value="id"/>}
                               label="Verify your ID"
                               microLoading={idVerifyLoading}
                               onClick={() => {

                                   getOnfidoParamsApi()
                               }}
                           />
                       </div>

                   </div>

               }
           />

           <HelpModal
               cssClass={"limit-reached-modal"}
               open={limitReachedModal.open}
               close={()=>{
                   setLimitReachedModal({...limitReachedModal, open:false});
               }}
               disableCross={true}
               body={
                   <div className="prototype-criteria-modal-data">
                       <div className="mb-20 text-center">
                           <Text  type={"h2"} fontWeight={"medium-font"}>You've reached the maximum number of attempts. Please try again in</Text>
                       </div>
                       <div className="d-flex justify-content-center align-items-center mt-32 mb-32">
                           <Icon value={"time"} size={"large"} />
                           <CountdownTimer timerData={limitReachedModal.timer} />
                       </div>
                       <div className="d-flex justify-content-center duplicate-test-modal-action">
                           <Button
                               size={"large"}
                               type="primary"
                               label="Close"
                               onClick={() => {

                                   setLimitReachedModal({...limitReachedModal, open:false});
                               }}
                           />
                       </div>

                   </div>

               }
           />
       </>
    );
});