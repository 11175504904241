import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import moment from 'moment';
import { HamburgerMenu } from './../HamburgerMenu/HamburgerMenu';
import { Icon } from './../Icon/Icon';
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";
import ReportTesterModal from "../Modal/report_tester";
import { EmptySearchPlaceholder } from "../EmptySearchPlaceholder/EmptySearchPlaceholder";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { useLocation } from "react-router-dom";
import { TestResultQuestionImage } from "../QuestionImage/TestResultQuestionImage";

export const ByUserTable = ({ metaData, isSharedLink, answerArray, sortData, hideParticipantModal, reportAnswerModal, search, test, loadMore, loadMoreLoading, clearSearch, question, tableClass, methodology,logicDestination,questionArray }) => {

    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    // highlight text if matches
    const escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    );

    const location = useLocation();

    const [reportTesterModal, setReportTesterModal] = useState({
        open: false,
        test_result_id: ''
    });

    const Highlight = ({ children = '' }) => {
        const patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
        const parts = String(children).split(patt);
        if (search) {
            return parts.map((part, index) => (
                patt.test(part) ? <mark className={'search-highlight'} key={index}>{part}</mark> : part
            ));
        } else {
            return children;
        }
    };

    const calculateTime = (time) => {
        var d = Math.floor(time / (3600 * 24));
        var h = Math.floor(time % (3600 * 24) / 3600);
        var m = Math.floor(time % 3600 / 60);
        var s = Math.floor(time % 60);

        if (m > 0) {
            return (`${m}min ${s}sec`);

        } else if (s > 0) {
            return (` ${s}sec`);
        } else {
            return ('-');
        }
    }
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;
    
        // Update state based on the scroll position
        setIsScrolled(scrolled);
      };
      useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;
    
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
    
          // Remove the event listener when the component unmounts
          return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
          };
        }
      }, []);

     const logicDestinationData = (selectedQuestion,userAnswer)=>{

          //console.log(selectedQuestion)
         // console.log(userAnswer)
          //console.log(questionArray)

         var selectedQuestionIndex = questionArray.findIndex(x=>x.id==selectedQuestion.id);

          var selectedQuestionIndexData = 0;
          if(selectedQuestionIndex !== -1){
              selectedQuestionIndexData = selectedQuestionIndex;
          }

         if(selectedQuestion && selectedQuestion.surveyoptions && selectedQuestion.surveyoptions.length>0){

             var destination = '';
             var destinationArray = [];

             var questionData = JSON.parse(selectedQuestion.questions);

             if(questionData.is_logic===1){

                 // console.log('9798889')


                 selectedQuestion.surveyoptions.forEach((option,i)=>{

                     // console.log(option.id)
                     // if(userAnswer === option.id){

                         // console.log('9798')

                     // console.log(questionData.jumpTo[i])
                         if(questionData.jumpTo[i] !== "End Survey"){

                             if(questionData.jumpTo[i] === selectedQuestionIndexData + 2){


                                 destination = "Next question";
                                 destinationArray.push({id:parseInt(option.id),'destination':destination,'nextQuestionIndex':questionData.jumpTo[i]})
                             }else{
                                 destination = "Question " + questionData.jumpTo[i];
                                 destinationArray.push({id:parseInt(option.id),'destination':destination,'nextQuestionIndex':questionData.jumpTo[i]})
                             }

                         }else{
                             destination = "End survey";
                             destinationArray.push({id:parseInt(option.id),'destination':destination,'nextQuestionIndex':''})
                         }
                     // }

                 })

                   console.log(destinationArray)

                 // console.log(destinationArray.find(x=> x.id == userAnswer))

                 var selectedOptionLogicDestination = destinationArray.find(x=> parseInt(x.id) === parseInt(userAnswer));

                 console.log(selectedOptionLogicDestination)
                 if(selectedOptionLogicDestination){


                     var nextQuestionData = '';

                     if(questionArray && questionArray.length > 0 && selectedOptionLogicDestination.nextQuestionIndex){


                         // console.log(questionArray[selectedOptionLogicDestination.nextQuestionIndex])
                         if(questionArray[selectedOptionLogicDestination.nextQuestionIndex-1]){


                            var findQuestion = (questionArray[selectedOptionLogicDestination.nextQuestionIndex-1].questions);

                            nextQuestionData = findQuestion.question
                         }
                     }

                     return(
                         <>
                             <Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={'navigate-text'}>
                                 Jumped to
                             </Text>

                             <Text type={'body-text-2'} fontWeight={'semi-bold-font'}
                                   cssClasses={'navigate-text-question'}>
                                 {selectedOptionLogicDestination.destination}

                                  {selectedOptionLogicDestination.destination !== 'End survey' && nextQuestionData &&
                                  <span className=" info-wrap position-relative">


                                      <Icon value={"information"} colorClass={'gray-900-svg'} size="medium" />
                                     <div className="tooltip-wrapper top-tooltip">
                                         <Text type='body-text-2' id='tooltip'
                                               fontWeight='normal-font'>
                                             <>
                                                 Question {selectedOptionLogicDestination.nextQuestionIndex} - {nextQuestionData}
                                                 {/*{result.logicDestination.nextQuestionData}*/}
                                             </>
                                         </Text>
                                     </div>
                                 </span>
                                  }

                             </Text>
                         </>
                     );
                 }



             }

         }

     }
    return (
        <>
            <div ref={scrollContainerRef} className={`max-height-table  ${isScrolled ? 'scrolled' : ''} ${tableClass?tableClass:''} ${answerArray && answerArray.length>8 ? "responsive-table-scroll":""}`}>
                <table className="table byuser-table">
                    <thead>
                        <tr>
                            <th className="bd-thcol-1" >
                                <div  className={`justify-content-between`}>
                                    Participant
                                </div>
                            </th>
                            <th className="bd-thcol-2">Answer</th>

                            {logicDestination &&
                                <th className="bd-thcol-3">Logic destination</th>
                            }

                            {methodology && methodology=="preference-test" &&
                            <th className="bd-thcol-3">Preferred design</th>
                            }
                            <th className={`${!methodology?'bd-thcol-3':'bd-thcol-4'}`}>
                                <div onClick={() => sortData('time')} className={`${(metaData.sort_time) ? metaData.sort_time : ''} col-sort justify-content-between`}>
                                    Time spent <span>
                                        {/* {metaData.sort_time ?
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-applied.svg"
                                        } alt="icon" width={20} />
                                        :
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-icon.svg"
                                        } alt="icon" width={20} />
                                    } */}

                                        {metaData.sort_time == "asc" &&
                                            <Icon value={'sortup'} />
                                        }
                                        {metaData.sort_time == "desc" &&
                                            <Icon value={'sortdown'} />
                                        }
                                        {!metaData.sort_time &&
                                            <Icon value={'sort-icon'} />
                                        }


                                    </span>
                                </div>
                            </th>
                            <th className={`${!methodology?'bd-thcol-4':'bd-thcol-5'}`}>
                                <div onClick={() => sortData('date')} className={`${(metaData.sort_date) ? metaData.sort_date : ''}  col-sort justify-content-between`}>
                                    Date <span>
                                        {/* {metaData.sort_date ?
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-applied.svg"
                                        } alt="icon" width={20} />
                                        :
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-icon.svg"
                                        } alt="icon" width={20} />
                                    } */}
                                        {metaData.sort_date == "asc" &&
                                            <Icon value={'sortup'} />
                                        }
                                        {metaData.sort_date == "desc" &&
                                            <Icon value={'sortdown'} />
                                        }
                                        {!metaData.sort_date &&
                                            <Icon value={'sort-icon'} />
                                        }
    
                                    </span>
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {answerArray && answerArray.map((result, index) => {

                            return (
                                <tr id={`hide-row-${result.id}`} className={` ${(result.result && result.result.is_reported) ? 'disabled-row-1' : ''}`}>
                                    <td className="bd-q-pat-col">

                                        {result.result &&
                                            (result.result.user_type === 'Unknown')?result.result.user_type+" ":result.result.user_type
                                        }

                                        {result.result && result.result.result_id ?
                                            result.result.result_id
                                            :
                                            index + 1
                                        }

                                    </td>
                                    <td className="bd-q-ans-col">
                                        <div className={`bd-qu-td-ans ${test.language == "ar" ? "arabic-font" : ""}`}>
                                            {result.type === 'free' &&
                                                <><Highlight>{result.answer}</Highlight></>
                                            }
                                            {result.type === 'single' &&
                                                <div className={`${result.option_image ? 'question-result-with-img d-flex align-items-center by-user-que-wimg' : 'no-question-image-td'}`}>

                                                    
                                                    {result.option_image
                                                    ?
                                                    <><TestResultQuestionImage url={result.option_image.image_path} type="option" />
                                                    </>
                                                    :
                                                    <>
                                                        {metaData.anyOptionHaveImage && 
                                                        <div className="no-question-image">-</div>
                                                        }
                                                    </> 
                                                    }
                                                    {(result.other_option_value) ?
                                                    <p key={index} className={`${test.language == "ar" ? "arabic-font" : ""}`}>
                                                    <Highlight>{result.option}</Highlight>{(result.option == "Other" && result.other_option_value) && ": ".concat(result.other_option_value)}{(result.option == "آخر" && result.other_option_value) && ": ".concat(result.other_option_value)}
                                                    </p>
                                                    :
                                                    <Highlight>{result.option}</Highlight>}
                                                </div>
                                            }
                                            {result.type === 'multiple' && result.option.map((items, index) => {
                                                return (
                                                    <div className={`${result.option_image ? 'question-result-with-img d-flex align-items-center by-user-que-wimg' : 'no-question-image-td'}`}>
                                                        {console.log(result)}
                                                        {result.option_image && result.option_image[index] ?
                                                        <TestResultQuestionImage url={result.option_image[index].image_path} type="option" />
                                                        :
                                                        <>
                                                            {metaData.anyOptionHaveImage && 
                                                            <div className="no-question-image">-</div>
                                                            }
                                                        </> 
                                                        }
                                                        {(result.other_option_value) ?
                                                        <p key={index} className={`${test.language == "ar" ? "arabic-font" : ""}`}>
                                                            <Highlight>{items}</Highlight>{items == "Other" && result.other_option_value && ": ".concat(result.other_option_value)}{items == "آخر" && result.other_option_value && ": ".concat(result.other_option_value)}
                                                        </p> :
                                                        <p key={index} className={`${test.language == "ar" ? "arabic-font" : ""}`}>
                                                            <Highlight>{items}</Highlight>
                                                        </p>}
                                                    </div>
                                                );
                                            })
                                            }
                                            
                                            {result.type === 'ratingscale' &&
                                                <><Highlight><RatingScaleIcon selected={true} type={question.type=="Numeric"?"numbers":(question.type=="Stars"?"stars":question.type)} value={result.answer}  scale={question.scale}/></Highlight></>
                                            }
                                        </div>
                                    </td>

                                    {logicDestination &&
                                        <td className="bd-logic-question-col">
                                            <div className="dt-question-wrap">
                                                {logicDestinationData(result.logicDestination,result.answer)}
                                            {/*{result.logicDestination && result.logicDestination.destination &&*/}
                                                <>
                                                    {/*<Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={'navigate-text'}>*/}
                                                    {/*    Jumped to*/}
                                                    {/*</Text>*/}

                                                    {/*<Text type={'body-text-2'} fontWeight={'semi-bold-font'}*/}
                                                    {/*      cssClasses={'navigate-text-question'}>*/}
                                                    {/*     {(result.logicDestination.destination !== 'End survey' && result.logicDestination.destination !== 'Next question')?'Question':''} {result.logicDestination.destination}*/}

                                                    {/*    {result.logicDestination.destination !== 'End survey' &&*/}
                                                    {/*    <span className=" info-wrap position-relative">*/}


                                                    {/*         <Icon value={"information"} colorClass={'gray-900-svg'} size="medium" />*/}
                                                    {/*        <div className="tooltip-wrapper top-tooltip">*/}
                                                    {/*            <Text type='body-text-2' id='tooltip'*/}
                                                    {/*                  fontWeight='normal-font'>*/}
                                                    {/*                <>*/}
                                                    {/*                    {result.logicDestination.nextQuestionData}*/}
                                                    {/*                </>*/}
                                                    {/*            </Text>*/}
                                                    {/*        </div>*/}
                                                    {/*    </span>*/}
                                                    {/*    }*/}

                                                    {/*</Text>*/}
                                                </>
                                            {/*}*/}
                                            </div>
                                        </td>
                                    }

                                    {methodology && methodology=="preference-test" &&
                                    <td className="bd-preferred-design-col">{result.preferred_design?result.preferred_design:"-"}</td>
                                    }
                                    <td className="bd-time-col">{calculateTime(result.time_spent)}</td>
                                    <td className="bd-q-date-col">
                                        <div className="bd-col-action-wrap d-flex">
                                            <span>{moment(result.created_at).format('DD/MM/Y')}</span>

                                        </div>
                                    </td>
                                    <td className="tableDropdown">
                                        {result.result && result.result.user_type !== 'Unknown' && (location.pathname.substr(1, 1) !== 'v') &&
                                            <span
                                                className="hamburger-menu ">

                                                <Dropdown className="dropdown">
                                                    <Dropdown.Toggle
                                                        id={"dropdownMenuButton" + result.id}
                                                        className="dropdown-toggle"
                                                    >
                                                        <span className="menu-item">
                                                            
                                                            <Icon value={'Quick menu'} colorClass={'gray-900-svg hamburger-menu-img'}/>
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className={'hamburger-items'}>
                                                        <div className="drop-down-link-hold byuser-table-link-hold">
                                                            {/*<Dropdown.Item onClick={() => hideParticipantModal(result.result.id, (result.result && result.result.is_hidden) ? true : false)}>*/}
                                                            {/*    <span>{(result.result && result.result.is_hidden) ? 'Show participant' : 'Hide participant'}</span>*/}
                                                            {/*    <Icon value="eye" size="medium" />*/}
                                                            {/*</Dropdown.Item>*/}
                                                            <Dropdown.Item onClick={() => {
                                                                // open report tester modal
                                                                setReportTesterModal({
                                                                    open: true,
                                                                    test_result_id: result.result.id
                                                                });
                                                                //reportAnswerModal(result.result.id)
                                                            }}>
                                                                <span>Report participant</span>
                                                                <Icon value="warning" size="medium" />
                                                            </Dropdown.Item>
                                                        </div>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        {answerArray && answerArray.length == 0 && (
                            <tr>
                                <td colSpan={5} className="border-0">
                                    <div className="text-center">
                                        <EmptySearchPlaceholder type={metaData && metaData.questionType === 'ratingscale'?"rating":null} clearSearch={clearSearch} cssClass="by-user-placeholder" />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {metaData.load_more && !loadMoreLoading && (
                <div className={"load-more-data"}>
                    <Button type="primary"
                        size={`large`}
                        label="Load more"
                        onClick={() => loadMore()}
                    />
                </div>
            )}
            {metaData.load_more && loadMoreLoading && (
                <div className={"load-more-data"}>
                    <Button type="primary"
                        size={`large`}
                        onClick={() => { }}
                        microLoading={true}
                    />
                </div>
            )}

            <ReportTesterModal
                test={test}
                openModal={reportTesterModal.open}
                selectedTestResultId={reportTesterModal.test_result_id}
                close={() => {
                    setReportTesterModal({ ...reportTesterModal, open: false });
                }}
                confirm={(type, totalParticipantSelected) => {

                    // set reported data
                    // setReportedData({
                    //     'status':type,
                    //     'totalSelectedParticipant':totalParticipantSelected
                    // });
                    // isReportedTester(true);
                    setReportTesterModal({ ...reportTesterModal, open: false });
                    sessionStorage.setItem("is_reported_tester", "true");

                    // hide instruction model if user has submit request
                    // if(type !== 'draft'){
                    //     setConfirmedTesterReportModal(true);
                    // }
                }}
            />
        </>
    )
}