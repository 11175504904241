import React, { useState, useRef, useEffect } from "react";
import { Accordian } from './../Accordion/Accordion';
import { Text } from './../Text/Text';
import { Tooltip } from './../Tooltip/Tooltip';
import { Icon } from "../../../themes/userq/Icon/Icon";
import exportAsImage from "../../../../lib/exportAsImage";
export const LikertScaleChart = ({ test, data, likertQuestion }) => {


    const ref = useRef();
    const tipref = useRef();
    const exportPngRef = useRef();
    const [accordians, setAccordians] = useState({});


    const [tooltip, showTooltip] = useState({
        tooltipLeft: 0,
        tooltipTop: 0,
        tooltipData: "",
        open: false,
        show: false
    });

    const handlePointerMove = (tooltipdata) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ? tipref.current.clientWidth : 150;


                var tool_height = tipref && tipref.current ? tipref.current.clientHeight + 12 : 106;




                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2;
                let containerY = ('clientY' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height - 10;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if (event.clientX + tool_width / 2 > window_width) {
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 - (event.clientX + tool_width / 2 - window_width);

                }
                if (event.clientX - tool_width / 2 < 0) {
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 + (tool_width / 2 - event.clientX);
                }

                showTooltip({
                    tooltipLeft: containerX,
                    tooltipTop: containerY,
                    tooltipData: tooltipdata,
                    open: true,
                    show: tooltip.open && tipref && tipref.current ? true : false,
                    tipPostion: tipPosition
                });
            });
    }

    const colors = [
        "#FF6DB1",
        "#9759CB",
        "#F2C690",
        "#698CE7",
        "#31F0E5",
        "#378B10",
        "#E32F83",
        "#793EAB",
        "#D7A56A",
        "#3668E7",
        "#11BFB4",
        "#164103",
        "#FF8AC1",
        "#AC7AD5",
        "#FFE2C0",
        "#9EB4ED",
        "#90F2EC",
        "#BFF0A8",
        "#A72561",
        "#5C208D",
        "#C38D4D",
        "#0D3BB0",
        "#0A877F",
        "#A72561",
        "#5C208D",
        "#C38D4D",
        "#06236C",
        "#045A54",
    ];

    var accordion = {};
    useEffect(()=>{
        for (let i = 0; i < likertQuestion.subQuestions.length; i++) {
            if(i===0){
                accordion[likertQuestion.subQuestions[i]] = true;
            }else{
                accordion[likertQuestion.subQuestions[i]] = false;
            }
        }
        setAccordians({...accordion});
    },[likertQuestion])



    return (
        <div className="likert-scale-chart" ref={ref} style={{ position: 'relative' }}>
            {likertQuestion.subQuestions && likertQuestion.subQuestions.map((element, index) => (
                <div key={"chart" + index} className="likert-scale-chart-accordion">
                    <Accordian
                        title={<p className={`${test.language == "ar" ? "arabic-font" : ""}`}>{element}</p>}
                        open={accordians[element] ? true : false}
                        onChange={() => {
                            let new_accordians = Object.assign({}, {...accordians});

                            for (var key in accordians) {
                                if (key == element) {
                                    if (accordians[key]) {
                                        new_accordians[key] = false;
                                    } else {
                                        new_accordians[key] = true;
                                    }
                                }

                            }
                            setAccordians({...new_accordians});
                        }}
                    >
                        <div className="position-relative likret-scale-chart-wrap">
                            <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Likert scale")}>
                                <Icon value={'download'} size={'small'} hover={'true'}></Icon>
                                <div className="tooltip-wrapper top-tooltip">
                                    <p class="body-text body-text-2 normal-font ">Download</p>
                                </div>
                            </div>

                            <div className="likertscale-subquestion-chart"  ref={exportPngRef}>
                                {likertQuestion.options.map((option, i) => (
                                    <div key={"chart-option-" + i}>
                                        {/* <div className="subquestion-chart-outer" style={{ height: "200px", backgroundColor: "#F0F0F0" }}> */}
                                        <div className="subquestion-chart-outer" onPointerMove={handlePointerMove(data.filter(function (el) {
                                            return el.x == option &&
                                                el.y == element;
                                        })[0].heat == 1 ?
                                            data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].heat + " participant"
                                            :
                                            data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].heat + " participants")}
                                             onPointerLeave={() => { showTooltip({ ...tooltip, open: false, show: false }) }}>

                                            <div
                                                className="subquestion-chart-inner"
                                                style={{
                                                    height: data.filter(function (el) {
                                                        return el.x == option &&
                                                            el.y == element;
                                                    })[0].percent_participants+"%",
                                                    backgroundColor: colors[i]
                                                }}


                                            >

                                                <Text type="h4">
                                                    {data.filter(function (el) {
                                                        return el.x == option &&
                                                            el.y == element;
                                                    })[0].percent_participants+"%"}
                                                </Text>

                                            </div>


                                        </div>
                                        <div><Text type="body-text-3" fontWeight="medium-font" cssClasses={test.language == "ar" ? "arabic-font" : ""}>{option}</Text></div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </Accordian>
                </div>
            ))}
            {tooltip.open ? (
                <>
                    <Tooltip
                        ref={tipref}
                        type={tooltip.tipPosition}
                        key={Math.random()} // needed for bounds to update correctly
                        style={{ zIndex: 0, display: tooltip.show ? "block" : 'block', whiteSpace: "nowrap", width: "auto", minWidth: "150px", margin: 0, position: "absolute", left: (tooltip.tooltipLeft) + "px", top: (tooltip.tooltipTop) + "px" }}
                        data={tooltip.tooltipData}
                        className="tooltipChart"

                    />
                </>
            ) : (
                <></>
            )}

        </div>
    )
}