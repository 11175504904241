import React, { useState, useEffect } from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import { preventClick } from '../../lib/helpers';
import { Modal } from 'react-bootstrap';
import InputField from '../layouts/elements/fields/input';
import toast from 'react-hot-toast';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession';
// import { FullPageLoadingIcon } from './../loader/full_page_loader_lcon_New';
import { FullPageLoadingIcon } from '../loader/full_page_loader_lcon_New';

export default function ConfirmEmail() {

    let { code } = useParams();

    const [formValues, setFormValues] = useState({email:'', confirm_email:'', code: code});
    const [isLoading, setLoading] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        changeAndVerifyEmail()
        document.title = process.env.REACT_APP_NAME+" - Confirm Email";
    }, []);

    const changeAndVerifyEmail = () => {
        
        setLoading(true);

        var body = formValues;

        
        fetch(process.env.REACT_APP_API_END_POINT+'verify-email', {
            method: 'POST',
            body: JSON.stringify(formValues),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);
            if(response.success){
                ReactSession.set("token",null);
                ReactSession.set("user",null);
                if(response.user_role=="Researcher"){
                  navigate('/researcher/sign-in');
                }else if(response.user_role=="Tester"){
                  navigate('/tester/sign-in');
                }else{
                  navigate('/researcher/sign-in');
                }
            } else {
                    toast(
                    <div className="toastinner">
                      {response.message}
                      <a
                        onClick={() => {
                          toast.dismiss();
                        }}
                      >
                        &times;
                      </a>
                    </div>,
                    {
                      className: "errortoast",
                      position: "bottom-center",
                      duration: 3000,
                    }
                  );
            }
        });
    }
    
    return (
        <div>
            {(isLoading==true) &&
            <FullPageLoadingIcon/> 
            }  
        </div>
    )
}