export const testDefaultCardsData= [
    // {
    //     title: 'Card sorting',
    //     description: <>You'll be asked to look at a list of cards, and drag them into groups that you think would be best. We'll also ask you to give each group an appropriate name. <br/>Remember, there’s no right or wrong answer here - just do what comes naturally.</>,
    //     image:'cardsorting-placeholder.svg',
    //     isComingSoon:false,
    //     methodology:'Card Sorting'
    // },
    // {
    //     title: 'Preference test',
    //     description: <>We’ll ask your preference between some options that will be shown. Before indicating your choice, please take a moment to carefully review the options. <br/>Remember, there’s no right or wrong answer here - just do what comes naturally.</>,
    //     image:'preferencetest-placeholder.svg',
    //     isComingSoon:false,
    //     methodology:'Preference Test'
    // },
    // {
    //     title: 'Tree test',
    //     description: <>You’ll be asked to find certain items in a list of categories. Click through the list, until you locate the place where you think the item would be found. If you make a mistake, don’t worry, you can always go back by clicking the previous category. <br/>Remember, there’s no right or wrong answer here - just do what comes naturally.</>,
    //     image:'treetest-placeholder.svg',
    //     isComingSoon:false,
    //     methodology:'Tree Test'
    // },
    // {
    //     title: 'Survey',
    //     description: <>We’ll ask you to answer to several questions. Please take a moment to carefully review the question before giving your answer.<br/> Remember, there’s no right or wrong answer here - just do what comes naturally.</>,
    //     image:'survey-placeholder.svg',
    //     isComingSoon:false,
    //     methodology:'Survey'
    // },
    // {
    //     title: '5 second test',
    //     description: <>We'll be showing you a design on the following screen. You will have 5 seconds to explore it and understand what it's about. After 5 seconds, the design will disappear and you will be asked to answer some questions related to it. <br/>Remember, there’s no right or wrong answer here - just do what comes naturally.</>,
    //     image:'fivesec-placeholder.svg',
    //     isComingSoon:false,
    //     methodology:'Five Seconds Test'
    // },{
    //     title: 'First click',
    //     description: 'Check where users first click on an interface to complete their task.',
    //     image:'firstclick-placeholder.svg',
    //     isComingSoon:true,
    //     methodology:'First Click'
    // },
    {
        title: 'Prototype',
        description: 'Understand how users navigate your digital product, measure how easy it is for them to complete specific tasks.',
        image:'firstclick-placeholder.svg',
        isComingSoon:true,
        methodology:'Prototype test',
        link:'https://app.userq.com/p/82dc0d'
    },
    {
        title: 'Card sorting',
        description: 'Find out how users think content should be organised and labelled to create a validated information architecture.',
        image:'cardsorting-placeholder.svg',
        isComingSoon:false,
        methodology:'Card Sorting',
        link:'https://app.userq.com/p/e26cfd'
    },
    {
        title: 'Preference test',
        description: 'Measure impact by having users evaluate different designs to understand which ones perform better.',
        image:'preferencetest-placeholder.svg',
        isComingSoon:false,
        methodology:'Preference Test',
        link:'https://app.userq.com/p/09b244'
    },
    {
        title: 'Tree test',
        description: 'Pinpoint exactly where your users are getting lost within your existing “tree” of information & hierarchical structure.',
        image:'treetest-placeholder.svg',
        isComingSoon:false,
        methodology:'Tree Test',
        link:'https://app.userq.com/p/c0d484'
    },
    {
        title: 'Survey',
        description: 'Get your target customers to answer your burning questions and acquire precise insights and data points.',
        image:'survey-placeholder.svg',
        isComingSoon:false,
        methodology:'Survey',
        link:'https://app.userq.com/p/35787e'
    },
    {
        title: '5 second test',
        description: 'Discover the impact and comprehension of your product on users with a 5 Seconds Test.',
        image:'fivesec-placeholder.svg',
        isComingSoon:false,
        methodology:'Five Seconds Test',
        link:'https://app.userq.com/p/59850f'
    },{
        title: 'First click',
        description: 'Check where users first click on an interface to complete their task.',
        image:'firstclick-placeholder.svg',
        isComingSoon:true,
        methodology:'First click',
        link:'https://app.userq.com/p/346261'
    }
];