import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";

export default function HelpModal({
                                      open,
                                      heading,
                                      body,
                                      close,
                                      cssClass,
                                      btnLoading,
                                  }) {

    const navigate = useNavigate();

    const closeModal = () => {

        close();

    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap  confirmation-modal-wrap help-modal-wrap  ${cssClass}`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <Text type={'h3'}>{heading}</Text>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true}/>
                            </span>
                            </button>
                        </div>
                        <>{body}</>


                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
