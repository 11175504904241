import {handleApiErrorResponse} from "../lib/helpers";

export function getResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/get?test_id="+
        formValues.test_id+"&type="+
        formValues.type+"&guest_token="+
        formValues.guest_token+"&captcha="+
        formValues.captcha,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getScreeningResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/get-screening-result?test_id="+
        formValues.test_id+"&guest_token="+formValues.guest_token,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function saveScreeningResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/save-screening-result", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function validateResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/validateResult", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getReportTesterParticipant(formValues,token) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "researcher/test-results/get-report-tester-participants?test_id="+formValues.test_id,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getLoadMoreReportTesterParticipant(formValues,token) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "researcher/test-results/get-load-more-report-tester-participants?page="+
        formValues.page+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+"&test_id="+formValues.test_id,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTesterHistory(token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/result/getTesterHistory",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getLoadMoreTesterHistory(formValues,token) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/result/getLoadMoreTesterHistory?page="+
        formValues.page+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function saveTestResultQuestion(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/save-question", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function saveTaskResultService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/save-task", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function savePreferenceTestTaskResultService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/save-preference-test-task", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function saveSurveyResultQuestion(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/result/save-survey",
    {
      method: "POST",
      body: JSON.stringify(formValues),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          return Promise.reject(error);
      }

      return data;

  }).catch((res)=>{
      handleApiErrorResponse(res);
  });
}

export function saveFiveSecondResultQuestion(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/result/save-five-seconds",
    {
      method: "POST",
      body: JSON.stringify(formValues),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          return Promise.reject(error);
      }

      return data;

  }).catch((res)=>{
      handleApiErrorResponse(res);
  });
}

export function savePreferenceTestFollowupQuestions(formValues, token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/result/save-preference-test-questions",
      {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{
  
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;
  
        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }
  
        return data;
  
    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
  }

export function saveCardSortingResultService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/save-card-sorting", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function savePrototypeTesterInterctions(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/prototype-test/task/save/testerinteraction", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function exitPrototypeTask(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/prototype-test/task/exit", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function savePrototypeResultQuestion(formValues, token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/result/save-prototype-question-responses",
      {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{
  
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;
  
        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }
  
        return data;
  
    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
  }

  export function saveFirstclickTesterInterctions(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/result/first-click/task/save/testerinteraction", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });    
}

export function saveFirstclickResultQuestion(formValues, token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/result/save-firstclick-question-responses",
      {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{
  
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;
  
        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }
  
        return data;
  
    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
  }

export function saveTreeTestResultQuestion(formValues, token) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/result/save-treetest-question-responses",
        {
            method: "POST",
            body: JSON.stringify(formValues),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function saveTestResultGuestPasswordService(formValues, token) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/result/save-test-guest-password",
        {
            method: "POST",
            body: JSON.stringify(formValues),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}